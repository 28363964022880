import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { makeStyles } from "@material-ui/styles"
import { Link } from "react-router-dom"
import qs from "query-string"
import { observer } from "mobx-react"
import { color } from "../theme"
import { Input, Button } from "../components"
import { useStores } from "../models/root-store"
import { Snackbar } from "../components/Layout/Snackbar"
import { isStrongPassword } from "../utils"
import { useLocation } from "react-router-dom"
import { Fade } from "@material-ui/core"
import { joinClassnames } from "../utils/joinClassnames"

import logo from "../static/eteva-white.png"

const useStyles = makeStyles({
  root: {
    display: "flex",
    minHeight: "100vh",
    backgroundColor: color.palette.white,
  },
  loginContainer: {
    flex: 0.5,
    padding: "60px 80px",
    position: "relative",
  },
  header: {
    fontSize: 64,
    fontWeight: "normal",
    textTransform: "uppercase",
    color: color.text,
  },
  alignCenter: {
    textAlign: "center",
  },
  secondary: {
    fontSize: 18,
    color: color.text,
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  label: {
    fontFamily: "Bw Surco",
    fontWeight: 400,
    fontSize: 24,
    textIndent: 15,
    marginTop: 40,
    marginBottom: 10,
    color: color.palette.black,
  },
  input: {
    height: 70,
    fontSize: 20,
    backgroundColor: color.palette.offWhite,
    fontFamily: "Bw Surco",
    "&:placeholder": {},
  },
  formBottom: {
    display: "flex",
    marginTop: 32,
  },
  button: {
    fontSize: 22,
    padding: "14px 32px",
    fontWeight: 600,
  },
  newsContainer: {
    position: "relative",
    display: "flex",
    flex: 0.5,
    flexFlow: "column nowrap",
    justifyContent: "center",
    backgroundPosition: "bottom",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundColor: color.primary,
  },
  logoContainer: {
    zIndex: 3,
    display: "flex",
    flex: 0.8,
    flexFlow: "column nowrap",
    justifyContent: "center",
    alignItems: "center",
  },
  logo: {
    width: "30%",
  },
  done: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    background: color.palette.white,
    display: "flex",
    flex: 1,
    flexDirection: "column",
    zIndex: 999,
    justifyContent: "center",
    alignItems: "center",
  },
  linkContainer: {
    marginTop: 20,
    fontFamily: "Bw Surco",
    fontSize: "18px",
  },
  link: {
    color: "#005EB8",
  },
})

interface Props {}

const ResetPasswordScreen: React.ComponentType<Props> = observer(() => {
  const location = useLocation()
  const values = qs.parse(location.search)
  const { userStore } = useStores()
  const classes = useStyles()
  const { t } = useTranslation()
  const [password1, setPassword1] = useState("")
  const [password2, setPassword2] = useState("")
  const [done, setDone] = useState(false)
  const handlePassword1Change = (e: React.ChangeEvent<HTMLInputElement>) => setPassword1(e.target.value)
  const handlePassword2Change = (e: React.ChangeEvent<HTMLInputElement>) => setPassword2(e.target.value)

  const isDisabled = () =>
    !password1 ||
    !password2 ||
    password1 !== password2 ||
    !isStrongPassword(password1) ||
    !values ||
    !values.email ||
    !values.email.length ||
    !values.code ||
    !values.code.length ||
    done

  const handleSetPassword = async () => {
    if (!isDisabled()) {
      const res = await userStore.setPassword(
        values.code as string,
        decodeURIComponent(values.email as string),
        password1
      )
      if (res) setDone(true)
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault()
      e.stopPropagation()
      handleSetPassword()
    }
  }

  const renderFormBottom = () => (
    <div className={classes.formBottom}>
      <Button
        disabled={isDisabled()}
        onClick={handleSetPassword}
        loading={userStore.state === "IN_PROGRESS"}
        primary
        size="large"
        className={classes.button}
        label={t("common.accept")}
      />
    </div>
  )

  const renderForm = () => (
    <div className={classes.formContainer}>
      <p className={classes.label}>{t("login.password")}</p>
      <Input
        className={classes.input}
        type="password"
        placeholder={t("login.password")}
        value={password1}
        onChange={handlePassword1Change}
        onKeyDown={handleKeyDown}
      />
      <p className={classes.label}>{t("reset.repeatPassword")}</p>
      <Input
        className={classes.input}
        type="password"
        placeholder={t("reset.repeatPassword")}
        value={password2}
        onChange={handlePassword2Change}
        onKeyDown={handleKeyDown}
      />
      {renderFormBottom()}
    </div>
  )

  const renderDone = () => {
    if (!done) return null
    return (
      <Fade in={done}>
        <div className={classes.done}>
          <h1 className={joinClassnames(classes.header, classes.alignCenter)}>{t("reset.success1")}</h1>
          <p className={classes.alignCenter}>{values.admin ? t("reset.success2Admin") : t("reset.success2")}</p>
          {values.admin && (
            <div className={classes.linkContainer}>
              <Link className={classes.link} to={"/login"}>
                {t("login.signIn")}
              </Link>
            </div>
          )}
        </div>
      </Fade>
    )
  }

  return (
    <div className={classes.root}>
      <div className={classes.loginContainer}>
        {renderDone()}
        <h1 className={classes.header}>{t("reset.header")}</h1>
        <p>{t("reset.instructions")}</p>
        {renderForm()}
        <Snackbar />
      </div>

      <div className={classes.newsContainer}>
        <div className={classes.logoContainer}>
          <img className={classes.logo} src={logo} alt="logo" />
        </div>
      </div>
    </div>
  )
})

export default ResetPasswordScreen
