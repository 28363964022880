import React, { FC, useState, useEffect } from "react"
import { useFormStyles } from "./styles"
import {
  FormInput,
  FormWysiwyg,
  FormImage,
  FormToggleable,
  FormSubmit,
  FormSelect,
  FormContentSectionEditor,
  FormDayProgramSectionEditor,
} from "./FormFields"
import { FormField, FormSection } from "./FormTypes"
import { ContentSectionWithPreview, ImageWithPreview } from "../../utils/interfaces"
import { useStores } from "../../models/root-store"
import { IContentSection, IDayProgramSection } from "eteva-types"

export interface FormProps {
  content?: any
  section: FormSection
  onSubmit?: (unsavedState: any) => void
  onCancel?: () => void
  formId: string
}

export const Form: FC<FormProps> = ({ content, section, onSubmit, onCancel }) => {
  const classes = useFormStyles()
  const { formStore } = useStores()

  const [unsavedState, setUnsavedState] = useState({
    // Item type is sometimes needed when saving item - section.type === type
    type: section.type,
  } as Record<string, any>)
  const [editingIndex, setEditingIndex] = useState<undefined | number>(undefined)

  useEffect(() => {
    // An extra check to make sure that content sections have correct position values
    const currentSections = content && content["sections"] || []
    const sortedSections = currentSections
      .sort((a: ContentSectionWithPreview, b: ContentSectionWithPreview) => {
        return a.position - b.position
      })
      .map((section: ContentSectionWithPreview, index: number) => {
      return { ...section, position: index + 1 }
    })
    // Save content with updated section position values
    setUnsavedState(content?.id ? { ...content, ...{ sections: sortedSections } } : { type: section.type })
  }, [content])

  const getValue = (id: string) => (unsavedState && unsavedState[id]) || ""

  const onFieldChange = (fieldId: string, text: string) => {
    setUnsavedState({ ...unsavedState, ...{ [fieldId]: text } })
  }

  const onImageChange = (fieldId: string, imgObj: ImageWithPreview) => {
    setUnsavedState({
      ...unsavedState,
      ...{
        [fieldId]: imgObj.image,
        imagePreviewUrl: imgObj.imagePreviewUrl,
      },
    })
  }

  const onSectionRemoveClick = (existingSection: any) => {
    const newSections = unsavedState["sections"]
      .filter((section: ContentSectionWithPreview) => {
        return existingSection.position !== section.position
      })
      .map((section: ContentSectionWithPreview, index: number) => {
        return { ...section, position: index + 1 }
      })
    setUnsavedState({ ...unsavedState, ...{ sections: newSections } })
  }

  const onSectionEditClick = (existingSection: Partial<IContentSection | IDayProgramSection>) => {
    const sections = getValue("sections") || []
    const editingCard = sections.find((c: any) => c.position === existingSection.position)
    setEditingIndex(sections.indexOf(editingCard))
  }

  const onSectionMoveUpClick = (existingSection: Partial<IContentSection | IDayProgramSection>) => {
    if (existingSection.position === undefined) return
    const sections = getValue("sections") || []
    const newSections = [...sections]

    // Prevent moving position below 1
    if (existingSection.position === 1) return

    const sectionIndex = sections.indexOf(
      sections.find((c: IContentSection | IDayProgramSection) => c.position === existingSection.position)
    )
    const prevSection = sections.find(
      (c: IContentSection | IDayProgramSection) => c.position + 1 === existingSection.position
    )

    if (prevSection) {
      const prevSectionIndex = sections.indexOf(prevSection)
      // Day program section handling
      if ("section" in existingSection && existingSection.section !== prevSection.section) {
        return
      }
      newSections[sectionIndex] = { ...existingSection, position: existingSection.position - 1 }
      newSections[prevSectionIndex] = { ...prevSection, position: existingSection.position }
    }
    // Prevent funny stuff from happening if moving a section that's being edited
    if (editingIndex !== undefined && sectionIndex === editingIndex) setEditingIndex(editingIndex - 1)

    setUnsavedState({ ...unsavedState, ...{ sections: newSections } })
  }

  const onSectionMoveDownClick = (existingSection: Partial<IContentSection | IDayProgramSection>) => {
    if (existingSection.position === undefined) return
    const sections = getValue("sections") || []
    const newSections = [...sections]

    // Prevent moving position beyond last element
    if (existingSection.position === sections.length) return

    const sectionIndex = sections.indexOf(
      sections.find((c: IContentSection | IDayProgramSection) => c.position === existingSection.position)
    )
    const nextSection = sections.find(
      (c: IContentSection | IDayProgramSection) => c.position - 1 === existingSection.position
    )

    if (nextSection) {
      const nextSectionIndex = sections.indexOf(nextSection)
      // Day program section handling
      if ("section" in existingSection && existingSection.section !== nextSection.section) {
        return
      }
      newSections[sectionIndex] = { ...existingSection, position: existingSection.position + 1 }
      newSections[nextSectionIndex] = { ...nextSection, position: existingSection.position }
    }
    // Prevent funny stuff from happening if moving a section that's being edited
    if (editingIndex !== undefined && sectionIndex === editingIndex) setEditingIndex(editingIndex + 1)

    setUnsavedState({ ...unsavedState, ...{ sections: newSections } })
  }

  const onSubmitClick = () => {
    if (!onSubmit) return
    const toReturn = unsavedState

    if (unsavedState.imageId && unsavedState.imagePreviewUrl) delete unsavedState.imageId

    setUnsavedState({ type: section.type })
    formStore.setSectionDirty()
    onSubmit(toReturn)
  }

  const handleSaveSections = (fieldId: string, section: Partial<IContentSection | IDayProgramSection>) => {
    if (editingIndex !== undefined) {
      const sections = getValue(fieldId) || []
      sections[editingIndex] = section
      setUnsavedState({ ...unsavedState, ...{ [fieldId]: sections } })
      setEditingIndex(undefined)
    } else {
      const sections = getValue(fieldId) || []
      sections.push(section)
      setUnsavedState({ ...unsavedState, ...{ [fieldId]: sections } })
    }
  }

  const renderComponent = (field: FormField) => {
    switch (field.type) {
      case "submit":
        return (
          <FormSubmit
            key="submit"
            onSubmit={onSubmitClick}
            onCancel={onCancel}
            field={field}
            isEditing={editingIndex !== undefined}
            isDisabled={isSubmitDisabled()}
          />
        )
      case "image":
        return (
          <FormImage
            key={field.id}
            field={field}
            onChange={onImageChange}
            imagePreviewUrl={unsavedState.imagePreviewUrl || unsavedState.imageId}
          />
        )
      case "input":
        return <FormInput key={field.id} field={field} value={getValue(field.id)} onChange={onFieldChange} />
      case "wysiwyg":
      case "help":
        return <FormWysiwyg key={field.id} field={field} value={getValue(field.id)} onChange={onFieldChange} />
      case "select":
        return <FormSelect key={field.id} field={field} value={getValue(field.id) || []} onChange={onFieldChange} />
      case "contentSection":
        return (
          <FormContentSectionEditor
            key={field.id}
            field={field}
            onSave={handleSaveSections}
            contentSections={getValue(field.id)}
            onRemove={onSectionRemoveClick}
            onEditClick={onSectionEditClick}
            onMoveUpClick={onSectionMoveUpClick}
            onMoveDownClick={onSectionMoveDownClick}
          />
        )
      case "dayProgramSection":
        return (
          <FormDayProgramSectionEditor
            key={field.id}
            field={field}
            onSave={handleSaveSections}
            contentSections={getValue(field.id)}
            onRemove={onSectionRemoveClick}
            onEditClick={onSectionEditClick}
            onMoveUpClick={onSectionMoveUpClick}
            onMoveDownClick={onSectionMoveDownClick}
          />
        )
      default:
        return <p>Unknown field type: {field.type}</p>
    }
  }

  const renderToggleable = (field: FormField) => (
    <FormToggleable key={field.id} label={field.label}>
      {renderComponent({ ...field, label: "" })}
    </FormToggleable>
  )

  const isSubmitDisabled = () => {
    let isDisabled = false
    section.fields.map(field => {
      if (field.required) {
        if (field.type !== "image" && !unsavedState[field.id]) isDisabled = true
        if (field.type === "image" && !unsavedState.imagePreviewUrl && !unsavedState.imageId) isDisabled = true
        if (field.type === "contentSection" && !unsavedState["sections"]?.length) isDisabled = true
        if (field.type === "dayProgramSection" && !unsavedState["sections"]?.length) isDisabled = true
        if (field.type === "select" && !unsavedState[field.id]?.length) isDisabled = true
      }
    })
    return isDisabled
  }

  const { fields } = section

  return (
    <form className={classes.formContainer}>
      <div className={classes.inputContainer}>
        {fields.map(field => {
          return field.toggleable ? renderToggleable(field) : renderComponent(field)
        })}
      </div>
    </form>
  )
}
