import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import fi from "./locales/fi.json"

export type Language = {
  name: string
  value: string
}

export const languages = [
  { name: "Suomi", value: "fi" }, // First item is used as a fallback language
]

export default i18n.use(initReactI18next).init({
  fallbackLng: languages[0].value,
  resources: {
    fi: {
      translation: fi,
    },
  },
})
