import { makeStyles } from "@material-ui/core"
import React, { FC, useState } from "react"
import { useTranslation } from "react-i18next"
import { RootPaths } from "../routers/Paths"
import { color } from "../theme"
import { Button } from "./Button"
import ModalDialog from "./ModalDialog"
import DeleteIcon from "@material-ui/icons/DeleteForever"

const useStyles = makeStyles({
  tabContainer: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 15,
    flexWrap: "wrap",
  },
  button: {
    marginRight: 16,
  },
  text: {
    fontFamily: "Bw Surco",
    fontWeight: 600,
    fontSize: 16,
  },
  deleteButton: {
    backgroundColor: color.palette.angry,
    color: color.palette.white,
    "&:hover": {
      backgroundColor: color.palette.angry,
      boxShadow: `0px 3px 3px ${color.shadow}`,
      opacity: 0.666,
    },
  },
  icon: { marginRight: 6, width: 16, height: 16 },
})

export interface DeactivateButtonsProps {
  path: keyof typeof RootPaths
  onActivateClick?: () => void
  onDeactivateClick?: () => void
  onDeleteClick?: () => void
}

const contextOptions = ["activate" as const, "deactivate" as const, "delete" as const]

export const DeactivateButtons: FC<DeactivateButtonsProps> = ({
  path,
  onActivateClick,
  onDeactivateClick,
  onDeleteClick,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [activeModal, setActiveModal] = useState<typeof contextOptions[number]>()

  const handleModalLeftClick = () => {
    if (activeModal === "activate" && onActivateClick) onActivateClick()
    if (activeModal === "deactivate" && onDeactivateClick) onDeactivateClick()
    if (activeModal === "delete" && onDeleteClick) onDeleteClick()
    setActiveModal(undefined)
  }

  const handleModalRightClick = () => setActiveModal(undefined)

  return (
    <>
      {onActivateClick && (
        <Button onClick={() => setActiveModal("activate")} primary tight className={classes.button}>
          <span className={classes.text}>{t("common.activateSelected")}</span>
        </Button>
      )}
      {onDeactivateClick && (
        <Button onClick={() => setActiveModal("deactivate")} primary tight className={classes.button}>
          <span className={classes.text}>{t("common.deactivateSelected")}</span>
        </Button>
      )}
      {onDeleteClick && (
        <Button onClick={() => setActiveModal("delete")} primary tight className={classes.deleteButton}>
          <DeleteIcon className={classes.icon} />
          <span className={classes.text}>{t("common.deleteSelected")}</span>
        </Button>
      )}
      <ModalDialog
        isOpen={Boolean(activeModal)}
        onLeftClick={handleModalLeftClick}
        onRightClick={handleModalRightClick}
        title="common.continue?"
        text={`message.modal.${path}.${activeModal}ModalText`}
        leftButtonText="common.confirm"
        rightButtonText="common.cancel"
      />
    </>
  )
}
