export const palette = {
  black: "#1d1d1d",
  white: "#ffffff",
  offWhite: "#F7F7F7",
  orange: "#FFB422",
  orangeDarker: "#EB9918",
  pureOrange: "#FEA500",
  pureOrangeDarker: "#FE8000",
  veryPaleOrange: "#FFF4E2",
  lightGrayishRed: "#F5E9E9",
  graphite: "#413E3E",
  darkNavy: "#114E68",
  lime: "#A2D017",
  lightGrey: "#939AA4",
  lighterGrey: "#CDD4DA",
  lightestGrey: "#C1C1C1",
  angry: "#dd3333",
  transparentWhite: "rgba(255, 255, 255, 0.4)",
  moreTransparentWhite: "rgba(255, 255, 255, 0.2)",
  transparentBlack: "rgba(0, 0, 0, 0.1)",
  veryTransparentBlack: "rgba(0, 0, 0, 0.05)",
}
