import React, { FC } from "react"
import { makeStyles } from "@material-ui/core"
import { color } from "../theme"
import { useTranslation } from "react-i18next"

interface RadioButton {
  label: string
  value: string
}

export interface RadioButtonsProps {
  onRadioClick: (e: React.ChangeEvent<HTMLInputElement>) => void
  buttons: Array<RadioButton>
  checked: string
  disabled?: boolean
}

const useStyles = makeStyles({
  radioWrapperContainer: {
    margin: "0px 15px",
  },
  radioWrapper: {
    display: "flex",
    flexDirection: "row",
    marginTop: 10,
    alignItems: "center",
    "&:hover": {
      cursor: "pointer",
    },
    "& input": {
      position: "absolute",
      opacity: 0,
    },
    "&:hover input ~ $radioCircle": {
      backgroundColor: "#eae9e9",
    },
    "& input:checked ~ $radioCircle:after": {
      content: "''",
      display: "block",
      backgroundColor: color.primary,
      borderRadius: 50,
      width: 10,
      height: 10,
    },
    "& input:disabled:checked ~ $radioCircle:after": {
      content: "''",
      display: "block",
      backgroundColor: color.line,
      borderRadius: 50,
      width: 10,
      height: 10,
    },
  },
  radioCircle: {
    backgroundColor: "#FFFFFF",
    border: "1px solid #B8B5B5",
    borderRadius: 50,
    width: 20,
    height: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: 15,
    transition: "all 150ms",
  },
  innerRadioCircle: {
    backgroundColor: color.primary,
    borderRadius: 50,
    width: 10,
    height: 10,
  },
  radioText: {
    fontFamily: "Bw Surco",
    fontSize: 16,
    fontWeight: 500,
  },
  disabled: {
    cursor: "not-allowed",
  },
})

export const RadioButtons: FC<RadioButtonsProps> = props => {
  const { onRadioClick, buttons, checked, disabled } = props
  const classes = useStyles()
  const { t } = useTranslation()

  const renderRadioButton = ({ label, value }: RadioButton) => {
    return (
      <label htmlFor={value} key={value} className={`${classes.radioWrapper} ${disabled ? classes.disabled : null}`}>
        <input
          id={value}
          type="radio"
          value={value}
          checked={checked === value}
          onChange={onRadioClick}
          disabled={disabled}
        />
        <div className={classes.radioCircle} />
        <span className={classes.radioText}>{t(label)}</span>
      </label>
    )
  }

  const renderRadioButtons = () => {
    return buttons.map(button => renderRadioButton(button))
  }

  return <form className={classes.radioWrapperContainer}>{renderRadioButtons()}</form>
}
