import { Button as MButton, CircularProgress, Fade, makeStyles } from "@material-ui/core/"
import { ButtonProps as MButtonProps } from "@material-ui/core/Button"
import React from "react"
import { color } from "../../theme"

const useStyles = makeStyles({
  root: {
    fontWeight: 500,
    borderRadius: 24,
    transition: "all 250ms",
    position: "relative",
    cursor: "pointer",
    lineHeight: 1,
    textTransform: "none",
  },
  primary: {
    backgroundColor: color.primary,
    border: `1px solid ${color.palette.white}`,
    boxShadow: `0px 6px 6px ${color.shadow}`,
    color: color.text,
    "&:hover": {
      backgroundColor: color.primary,
      boxShadow: `0px 3px 3px ${color.shadow}`,
      opacity: 0.666,
    },
  },
  secondary: {
    backgroundColor: color.palette.white,
    border: `1px solid ${color.palette.white}`,
    color: color.secondary,
    boxShadow: `0px 6px 6px ${color.shadow}`,
    "&:hover": {
      backgroundColor: color.palette.white,
      boxShadow: `0px 3px 3px ${color.shadow}`,
      opacity: 0.666,
    },
  },
  loadingContainer: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: color.primary,
    borderRadius: 50,
  },
  circularProgress: {
    color: color.palette.white,
  },
  deleteButton: {
    backgroundColor: color.palette.angry,
  },
  small: {
    fontSize: "12px",
    padding: "10px 16px",
  },
  medium: {
    fontSize: "14px",
    padding: "11px 20px",
  },
  large: {
    fontSize: "16px",
    padding: "12px 24px",
  },
  tight: {
    borderRadius: 10,
  },
  disabled: {
    opacity: 0.8,
    color: color.palette.veryTransparentBlack,
  },
})

export interface ButtonProps {
  /**
   * Is this the principal call to action on the page?
   */
  primary?: boolean
  /**
   * Is the button's context loading?
   */
  loading?: boolean
  /**
   * How large should the button be?
   */
  size?: "small" | "medium" | "large"
  /**
   * Button content (if not children)
   */
  label?: string
  /**
   * Tighter border radius
   */
  tight?: boolean
}

export const Button: React.FC<MButtonProps & ButtonProps> = props => {
  const { id, disabled, children, className, loading, primary = false, size = "medium", label, tight, ...rest } = props
  const classes = useStyles()

  const getSizeStyle = () => {
    switch (size) {
      case "small":
        return classes.small
      case "medium":
      default:
        return classes.medium
      case "large":
        return classes.large
    }
  }

  const getSpinnerSize = () => {
    switch (size) {
      case "small":
        return 16
      case "medium":
      default:
        return 20
      case "large":
        return 24
    }
  }

  return (
    <MButton
      id={id}
      className={[
        classes.root,
        primary ? classes.primary : classes.secondary,
        tight ? classes.tight : null,
        disabled ? classes.disabled : null,
        getSizeStyle(),
        className,
      ].join(" ")}
      disabled={loading || disabled}
      {...rest}
    >
      {label || children}
      <Fade in={loading}>
        <div className={classes.loadingContainer}>
          <CircularProgress size={getSpinnerSize()} classes={{ root: classes.circularProgress }} color="secondary" />
        </div>
      </Fade>
    </MButton>
  )
}
