export const RootPaths = {
  index: "/",
  login: "/kirjaudu",
  resetPassword: "/palauta-salasana",
  users: "/kayttajat",
  userGroups: "/kayttajaryhmat",
  categories: "/kategoriat",
  subCategories: "/alakategoriat",
  contents: "/sisallot",
  dayPrograms: "/paivaohjelmat",
  analytics: "/analytiikka",
  appInfo: "/sovelluksen-tiedot",
} as const

export const SubPaths = {
  create: "/uusi",
  edit: "/muokkaa",
}
