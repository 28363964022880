import React, { FC } from "react"
import { Editor as WysiwygEditor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { EditorState } from "draft-js"
import { useEditorStyles } from "./styles"
import { toolbarSettings } from "../../utils/editor"

export interface EditorProps {
  state: EditorState
  onChange?: (arg0: EditorState) => void
  onBlur?: (arg0: EditorState) => void
}

export const Editor: FC<EditorProps> = ({ state, onChange, onBlur }) => {
  const { toolbar, wrapper, editor, button } = useEditorStyles()
  const TOOLBAR = toolbarSettings(button)

  const handleBlur = () => {
    if (onBlur) onBlur(state)
  }

  return (
    <WysiwygEditor
      toolbar={TOOLBAR}
      editorState={state}
      onEditorStateChange={onChange}
      toolbarClassName={toolbar}
      wrapperClassName={wrapper}
      editorClassName={editor}
      onBlur={handleBlur}
      stripPastedStyles
    />
  )
}
