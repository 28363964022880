import { RootStore } from "../root-store/root-store"
import { Instance, SnapshotOut, types as t, flow, getParent, cast } from "mobx-state-tree"
import { FlowType } from "eteva-rest-api"
import { ICategory, PageResponse } from "eteva-types"
import { api } from "../../services/api"
import { omit } from "ramda"
import { withEnvironment } from "../extensions"
import { SelectInputOption } from "../../components/SelectInput"

const SubCategoryModel = t.model("SubCategoryModel", {
  id: t.identifierNumber,
  title: t.string,
  imageId: t.maybeNull(t.number),
  active: t.boolean,
})

export type SubCategoryModelType = Instance<typeof SubCategoryModel>

export const SubCategoryStoreModel = t
  .model("SubCategoryStore")
  .props({
    subCategories: t.optional(t.array(SubCategoryModel), []),
    total: t.optional(t.number, 0),
    fetching: t.optional(t.boolean, false),
  })
  .extend(withEnvironment)
  .views(self => ({
    asSelectOptions: (): SelectInputOption[] => {
      return self.subCategories.map(it => ({ value: it.id, label: it.title }))
    },
  }))
  .actions(self => ({
    getAll: flow(function* (page: number, pageSize: number, search?: string, active?: boolean): FlowType {
      const { messageStore } = getParent(self) as RootStore
      self.fetching = true
      const response: PageResponse<ICategory> = yield api.subCategories.getAll({ page, pageSize, search }, active)
      self.fetching = false
      if (response) {
        self.subCategories = cast(response.results)
        self.total = response.total
        return true
      }
      messageStore.setMessage("message.error.common", "error")
      return false
    }),
    getOne: flow(function* (categoryId): FlowType {
      const { messageStore } = getParent(self) as RootStore
      self.fetching = true
      const result: ICategory = yield api.subCategories.getOne(categoryId)
      self.fetching = false
      if (result) {
        return result
      }
      messageStore.setMessage("message.error.common", "error")
      return null
    }),
    create: flow(function* (category: FormData): FlowType {
      const { messageStore } = getParent(self) as RootStore
      self.fetching = true
      const result: boolean = yield api.subCategories.create(category)
      self.fetching = false
      if (result) {
        messageStore.setMessage("message.success.subCategory.added", "success")
        return true
      }
      messageStore.setMessage("message.error.common", "error")
      return false
    }),
    update: flow(function* (category: FormData): FlowType {
      const { messageStore } = getParent(self) as RootStore
      self.fetching = true
      const result: boolean = yield api.subCategories.update(category)
      self.fetching = false
      if (result) {
        messageStore.setMessage("message.success.subCategory.updated", "success")
        return true
      }
      messageStore.setMessage("message.error.common", "error")
      return false
    }),
    deleteOne: flow(function* (categoryId): FlowType {
      const { messageStore } = getParent(self) as RootStore
      self.fetching = true
      const result = yield api.subCategories.delete(categoryId)
      self.fetching = false
      if (result) {
        messageStore.setMessage("message.success.subCategory.deleted", "success")
        return true
      }
      messageStore.setMessage("message.error.common", "error")
      return null
    }),
    changeItemStatuses: flow(function* (itemIds: number[], newStatus: boolean): FlowType {
      const { messageStore } = getParent(self) as RootStore
      self.fetching = true
      const response: Promise<boolean> = yield api.subCategories.updateActivity(itemIds, newStatus)
      self.fetching = false
      if (!response) messageStore.setMessage("message.error.common", "error")
      return response
    }),
  }))
  .postProcessSnapshot(omit(["fetching"]))
/**
  * Un-comment the following to omit model attributes from your snapshots (and from async storage).
  * Useful for sensitive data like passwords, or transitive state like whether a modal is open.

  * Note that you'll need to import `omit` from ramda, which is already included in the project!
  */

type SubCategoryStoreType = Instance<typeof SubCategoryStoreModel>
export interface SubCategoryStore extends SubCategoryStoreType {}
type SubCategoryStoreSnapshotType = SnapshotOut<typeof SubCategoryStoreModel>
export interface SubCategoryStoreSnapshot extends SubCategoryStoreSnapshotType {}
