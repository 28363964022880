import React, { useState, useEffect } from "react"
import { useHistory, useParams } from "react-router-dom"
import { observer } from "mobx-react"
import { CommonAttributes, ISubCategory } from "eteva-types"
import { makeStyles } from "@material-ui/core"
import { Screen, Loader, Form } from "../../components"
import { useStores } from "../../models/root-store"
import { FormSection, FormSelectField } from "../../components/Form/FormTypes"
import { SubCategoryWithPreview } from "../../utils/interfaces"
import { formatFormData } from "../../utils"
// import { ModificationDetails } from "../../components/ModificationDetails"
import { RootPaths } from "../../routers/Paths"

const useStyles = makeStyles({
  container: {
    padding: 10,
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    "@media (min-width:600px)": {
      padding: 50,
      display: "flex",
      flex: 1,
    },
  },
})

interface SubCategoryScreenProps {}

export const SubCategoryScreen: React.FC<SubCategoryScreenProps> = observer(() => {
  const {
    subCategoryStore: { fetching, getOne, create, update },
    categoryStore: { fetching: fetchingUserGroups, getAll: getCategories, asSelectOptions: categories },
    formStore: { setSectionDirty },
  } = useStores()
  const [subCategoryState, setSubCategoryState] = useState<Partial<SubCategoryWithPreview & CommonAttributes>>({})
  const { container } = useStyles()
  const history = useHistory()
  const { id } = useParams<{ id: string }>()

  const getSubCategoryWithId = async (id: number) => {
    const newSubCategory: ISubCategory = await getOne(id)
    if (newSubCategory) {
      setSubCategoryState({
        ...newSubCategory,
        categories: newSubCategory.categories?.map(it => it.id) || [],
      })
    }
  }

  useEffect(() => {
    ;(async () => {
      if (id && !isNaN(Number(id))) await getSubCategoryWithId(Number(id))
      await getCategories(0, 1000)
    })()
  }, [id])

  const handleSubmit = async (state: SubCategoryWithPreview) => {
    setSectionDirty()

    const formattedState = {
      ...state,
      categories: state.categories?.map(it => ({
        id: it,
      })),
    }

    const submitForm = new FormData()
    const formData = { ...formatFormData(formattedState) }
    submitForm.append("data", JSON.stringify(formData))
    if (state.imageFile) submitForm.append("image", state.imageFile)

    let success = false
    if (state.id) {
      success = await update(submitForm)
    } else {
      success = await create(submitForm)
    }

    if (success) history.push(RootPaths.subCategories)
  }

  const handleCancel = () => history.push(RootPaths.subCategories)

  const header = id ? "subCategories.edit" : "subCategories.add"

  const getFormFields = (type: string, title: string) =>
    ({
      type,
      title,
      fields: [
        {
          id: "title",
          type: "input",
          label: "form.title",
          required: true,
        },
        {
          id: "imageFile",
          type: "image",
          label: "form.image",
          required: true,
        },
        {
          id: "categories",
          type: "select",
          label: "form.categories",
          options: categories(),
          multiple: true,
          loading: fetchingUserGroups,
          required: true,
        } as FormSelectField,
        {
          id: "help",
          type: "help",
          label: "form.help",
        },
        {
          id: "submit",
          type: "submit",
          label: "submit",
          addLabel: "form.save",
          updateLabel: "form.update",
        },
      ],
    } as FormSection)

  const renderContent = () => {
    if (fetching) return <Loader />
    return (
      <div className={container}>
        <Form
          content={subCategoryState}
          formId="category"
          section={getFormFields("category", "")}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
        />
        {/* <ModificationDetails item={subCategoryState} /> */}
      </div>
    )
  }
  return <Screen headerTitle={header}>{renderContent()}</Screen>
})
