import React, { FC, useState } from "react"
import { useTranslation } from "react-i18next"
import { makeStyles } from "@material-ui/core"
import { Cancel } from "@material-ui/icons"

import { color } from "../../theme"
import { Image } from "../Image"
import { ImageCropper } from ".."

import AddIcon from "../../static/Add.svg"

const useStyles = makeStyles({
  container: {
    display: "flex",
    position: "relative",
  },
  title: {
    position: "absolute",
    left: 0,
    top: 0,
    backgroundColor: color.palette.black,
    color: color.palette.white,
    fontSize: 10,
    fontWeight: 600,
    fontFamily: "Bw Surco",
    padding: "3px 10px",
    zIndex: 999,
  },
  button: {
    width: 200,
    height: 200,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    transition: "all 250ms",
    borderRadius: 3,
    border: `1px solid ${color.line}`,
    backgroundColor: color.backgroundDarker,
    "&:hover": {
      backgroundColor: color.background,
    },
  },
  image: {
    width: 40,
    height: 40,
  },
  label: {
    fontFamily: "Bw Surco",
    fontSize: 14,
    fontWeight: 700,
    textTransform: "uppercase",
    marginTop: 20,
  },
  hiddenInput: {
    height: 0.1,
    width: 0.1,
    position: "absolute",
    zIndex: -1,
    opacity: 0,
  },
  preview: {
    width: "100%",
    // height: "100%",
    objectFit: "cover",
    border: `1px solid ${color.line}`,
    borderRadius: 3,
  },
  deleteButton: {
    position: "absolute",
    top: 10,
    right: 10,
    border: "none",
    backgroundColor: color.palette.transparentBlack,
    width: 20,
    height: 20,
    borderRadius: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    outline: "none",
    transition: "all 250ms",
    zIndex: 99,
    "&:hover": {
      opacity: 0.666,
    },
  },
  icon: {
    width: 15,
    height: 15,
    filter: "invert(100%)",
  },
  error: {
    color: color.error,
  },
})

interface Props {
  onChange?: (files: Blob | null) => void
  onDelete?: () => void
  previewUrl?: string | number
  title?: string
  className?: string
  style?: any
  id: string
  contentEditorImage?: boolean
}

export const ImageUploadInput: FC<Props> = props => {
  const { title, onChange, previewUrl, className, onDelete, style, id, contentEditorImage } = props
  const classes = useStyles()
  const { t } = useTranslation()

  // We hold the original image in state for image cropping
  const [image, setImage] = useState<string>()

  const handleInputChange = (img: Blob | null) => {
    if (!img) return
    const reader = new FileReader()
    reader.readAsDataURL(img)
    reader.onloadend = () => setImage(reader.result as string)
    onChange?.(img)
  }

  const renderTitle = () => {
    if (!title) return null
    return (
      <div className={classes.title}>
        <span>{title}</span>
      </div>
    )
  }

  const renderUpload = () => (
    <>
      <label className={classes.button} htmlFor={id}>
        <img className={classes.image} src={AddIcon} alt="" />
        <span className={classes.label}>{t("form.uploadImage")}</span>
      </label>
      <input
        className={classes.hiddenInput}
        id={id}
        type="file"
        onChange={e => handleInputChange(e.target.files ? e.target.files[0] : null)}
      />
    </>
  )

  const renderContent = () => {
    // If previewUrl is a number, it must be an image ID -> use a component to fetch the image with
    if (typeof previewUrl === "number") return <Image className={classes.preview} imageId={previewUrl} />
    if (image) return <ImageCropper imageSrc={image} onImageChange={onChange} contentEditorImage={contentEditorImage} />
    return renderUpload()
  }

  const renderRemoveButton = () => {
    if (!onDelete || typeof previewUrl !== "number") return null
    return (
      <button className={classes.deleteButton} onClick={onDelete}>
        <Cancel className={classes.icon} />
      </button>
    )
  }

  return (
    <div style={style} className={`${classes.container} ${className}`}>
      {renderRemoveButton()}
      {renderTitle()}
      {renderContent()}
    </div>
  )
}
