import React, { useState, useCallback, useRef, FC, CSSProperties } from "react"
import { makeStyles } from "@material-ui/core"
import ReactCrop, { Crop } from "react-image-crop"
import "react-image-crop/dist/ReactCrop.css"
import { Text } from "../Text"

interface Props {
  imageSrc?: string // base64 image
  onImageChange?: (files: Blob | null) => void
  contentEditorImage?: boolean
}

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    margin: "1.5em 0 1em",
  },
})

export const ImageCropper: FC<Props> = ({ imageSrc, onImageChange, contentEditorImage = false }) => {
  const classes = useStyles()

  const imgRef = useRef<HTMLImageElement | null>(null)
  const previewCanvasRef = useRef<HTMLCanvasElement | null>(null)

  const [crop, setCrop] = useState<Crop>({ unit: "%", width: 0, height: 0, x: 0, y: 0 })
  const [completedCrop, setCompletedCrop] = useState<Crop>()

  const onLoad = useCallback(img => (imgRef.current = img), [])

  if (!imageSrc) return null

  const handleCompleteCrop = async (c: Crop) => {
    const image = imgRef.current
    const canvas = previewCanvasRef.current
    const crop = c

    if (!canvas || !image || !crop) return
    const ctx = canvas.getContext("2d")
    if (!ctx) return

    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    const pixelRatio = window.devicePixelRatio

    canvas.width = (crop.width ?? 0) * pixelRatio * scaleX
    canvas.height = (crop.height ?? 0) * pixelRatio * scaleY

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0)
    ctx.imageSmoothingQuality = "high"

    ctx.drawImage(
      image,
      (crop.x ?? 0) * scaleX,
      (crop.y ?? 0) * scaleY,
      (crop.width ?? 0) * scaleX,
      (crop.height ?? 0) * scaleY,
      0,
      0,
      (crop.width ?? 0) * scaleX,
      (crop.height ?? 0) * scaleY
    )

    setCompletedCrop(c)

    const blob: Blob | null = await new Promise(resolve => {
      canvas.toBlob(blob => resolve(blob), "image/png", 1)
    })

    onImageChange?.(blob)
  }

  const previewCanvasStyle: CSSProperties = contentEditorImage
    ? {
        width: Math.round(completedCrop?.width ?? 0),
        height: Math.round(completedCrop?.height ?? 0),
      }
    : {
        width: "300px",
        height: completedCrop && completedCrop.y > 0 ? "300px" : 0,
        borderRadius: "50%",
        objectFit: "cover",
      }

  return (
    <div className={classes.container}>
      <ReactCrop
        src={imageSrc}
        onImageLoaded={onLoad}
        crop={crop}
        onChange={(newCrop: Crop) => setCrop(newCrop)}
        onComplete={(newCrop: Crop) => handleCompleteCrop(newCrop)}
      />

      {completedCrop && completedCrop.y > 0 && <Text className={classes.title} tx="common.preview" />}

      <canvas ref={previewCanvasRef} style={previewCanvasStyle} />
    </div>
  )
}
