import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import { Cancel } from '@material-ui/icons';

const color = {
  palette: {
    black: '#000',
    white: '#fff',
    transparentBlack: 'rgba(0,0,0,0.5)',
    line: '#ccc',
    backgroundDarker: '#f3f3f3',
    background: '#fafafa',
    error: '#f44336',
  },
  background: '#fff',
  line: '#eee',
  error: '#f44336',
};

const useStyles = makeStyles({
  container: {
    display: 'flex',
    position: 'relative',
  },
  title: {
    position: 'absolute',
    left: 0,
    top: 0,
    backgroundColor: color.palette.black,
    color: color.palette.white,
    fontSize: 10,
    fontWeight: 600,
    fontFamily: 'Bw Surco',
    padding: '3px 10px',
    zIndex: 999,
  },
  button: {
    width: 200,
    height: 200,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    transition: 'all 250ms',
    borderRadius: 3,
    border: `1px solid ${color.line}`,
    backgroundColor: color.background,
    '&:hover': {
      backgroundColor: color.background,
    },
  },
  audio: {
    width: '100%',
    border: `1px solid ${color.line}`,
    borderRadius: 3,
  },
  label: {
    fontFamily: 'Bw Surco',
    fontSize: 14,
    fontWeight: 700,
    textTransform: 'uppercase',
    marginTop: 20,
  },
  hiddenInput: {
    height: 0.1,
    width: 0.1,
    position: 'absolute',
    zIndex: -1,
    opacity: 0,
  },
  preview: {
    width: '100%',
    objectFit: 'cover',
    border: `1px solid ${color.line}`,
    borderRadius: 3,
  },
  deleteButton: {
    position: 'absolute',
    top: 10,
    right: 10,
    border: 'none',
    backgroundColor: color.palette.transparentBlack,
    width: 20,
    height: 20,
    borderRadius: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    outline: 'none',
    transition: 'all 250ms',
    zIndex: 99,
    '&:hover': {
      opacity: 0.666,
    },
  },
  icon: {
    width: 15,
    height: 15,
    filter: 'invert(100%)',
  },
  error: {
    color: color.error,
  },
});

interface Props {
  type: 'audio' | 'gif';
  onChange?: (file: File | null) => void;
  onDelete?: () => void;
  title?: string;
  className?: string;
  style?: React.CSSProperties;
  id: string;
}

const MediaFileUploadInput: FC<Props> = ({
  type,
  onChange,
  className,
  onDelete,
  style,
  id,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [fileLoaded, setFileLoaded] = useState(false);

  const handleInputChange = (file: File | null) => {
    setIsLoading(true);
    setTimeout(() => {
      onChange?.(file);
      setIsLoading(false);
      setFileLoaded(true);
    }, 2000); // Replace with actual upload logic
  };

  const renderUploadLabel = () => {
    if (isLoading) {
      return <div className={classes.label}>{t('form.uploading')}...</div>;
    } else if (fileLoaded) {
      return <span className={classes.label}>{t(`form.${type}Loaded`)}</span>;
    } else {
      return <span className={classes.label}>{t(`form.upload${type.charAt(0).toUpperCase() + type.slice(1)}`)}</span>;
    }
  };

  const renderUpload = () => {
    const fileType = type === 'audio' ? 'audio/*' : 'image/gif';
    return (
      <>
        <label className={classes.button} htmlFor={id}>
          {renderUploadLabel()}
        </label>
        <input
          className={classes.hiddenInput}
          id={id}
          type="file"
          accept={fileType}
          onChange={(e) => handleInputChange(e.target.files ? e.target.files[0] : null)}
        />
      </>
    );
  };

  const renderRemoveButton = () => {
    if (!onDelete) return null;
    return (
      <button className={classes.deleteButton} onClick={onDelete}>
        <Cancel className={classes.icon} />
      </button>
    );
  };

  return (
    <div style={style} className={`${classes.container} ${className}`}>
      {renderRemoveButton()}
      {renderUpload()}
    </div>
  );
};

export default MediaFileUploadInput;
