import React, { ReactElement, useState } from "react"
import { Table as MUITable, TableBody, CircularProgress, Fade } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import TableHeader from "./TableHeader"
import TableRow from "./TableRow"
import TableFooter from "./TableFooter"
import { color } from "../../theme"
import { useTranslation } from "react-i18next"
import { CommonAttributes } from "eteva-types"

const useStyles = makeStyles({
  tableContainer: {
    position: "relative",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    minHeight: 300,
  },
  root: {
    alignSelf: "center",
    backgroundColor: color.palette.white,
    maxWidth: "100%",
    overflowX: "auto",
    overflow: "visible",
    width: "100%",
    border: `1px solid ${color.line}`,
    borderRadius: 5,
  },
  table: {
    overflowX: "auto",
    width: "100%",
  },
  placeholderContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    justifyContent: "center",
    alignItems: "flex-start",
  },
  placeholderText: {
    fontSize: 30,
    fontWeight: 600,
    fontFamily: "Bw Surco",
    color: color.dim,
    textIndent: 50,
  },
  placeholderTextSub: {
    fontSize: 18,
    color: color.dim,
    textIndent: 50,
  },
  footerContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  emptyContainer: {
    width: 40,
    height: 70,
  },
  loadingContainer: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: color.backgroundDarker,
  },
})

export type TableProperty<T> = {
  key: keyof T
  title: string
}

export type TableProps<T> = {
  items: T[]
  properties: TableProperty<T>[]
  loading?: boolean
  total: number
  page?: number
  pageSize?: number
  onChangePage?: (event: any, toPage: number) => void
  edit?: boolean
  onEditClick?: (id: number) => void
  onSelectOne?: (id: number) => void
  onSelectAll?: () => void
  selectedItems?: number[]
  hideCheckbox?: boolean
}

/**
 * A somewhat reusable Table component.
 * Make sure keys of the objects passed as items that you want to display
 * match the provided headerTitles
 */
export function Table<T>(props: TableProps<T & CommonAttributes>): ReactElement {
  const classes = useStyles()
  const { t } = useTranslation()
  const {
    loading,
    total,
    page = 1,
    pageSize = 10,
    onChangePage,
    onEditClick,
    items,
    properties,
    edit,
    onSelectOne,
    onSelectAll,
    selectedItems,
    hideCheckbox,
  } = props

  const handlePageChange = (event: any, toPage: number) => {
    if (onChangePage) onChangePage(event, toPage)
  }

  const allSelected = () => {
    if (!selectedItems) return false
    return selectedItems.filter(it => items.find(item => item.id === it)).length >= items.length
  }

  const renderRows = () => {
    if (items && items.length) {
      return items.map(item => {
        const isRowSelected = () => {
          if (item.id && selectedItems) return selectedItems.indexOf(item.id) >= 0
          return false
        }
        return (
          <TableRow
            key={item.id}
            item={item}
            onEditClick={onEditClick}
            properties={properties}
            edit={edit}
            onSelectOne={onSelectOne}
            selected={isRowSelected()}
            hideCheckbox={hideCheckbox}
          />
        )
      })
    }
    return null
  }

  const renderFooter = () => (
    <TableFooter page={page} pageSize={pageSize} onChangePage={handlePageChange} total={total} />
  )

  const renderContent = () => {
    if (!total || !items.length) {
      return (
        <div className={classes.placeholderContainer}>
          <span className={classes.placeholderText}>{t("common.emptyTable")}</span>
          <span className={classes.placeholderTextSub}>{t("common.emptyTableSub")}</span>
        </div>
      )
    }
    return (
      <>
        <div className={classes.root}>
          <MUITable className={classes.table} padding="none">
            <TableHeader
              properties={properties}
              edit={edit}
              onSelectAll={onSelectAll}
              selected={allSelected()}
              hideCheckbox={hideCheckbox}
            />
            <TableBody>{renderRows()}</TableBody>
          </MUITable>
        </div>
        <div className={classes.footerContainer}>{renderFooter()}</div>
      </>
    )
  }

  return (
    <div className={classes.tableContainer}>
      {renderContent()}
      <Fade in={loading}>
        <div className={classes.loadingContainer}>
          <CircularProgress size={24} color="secondary" />
        </div>
      </Fade>
    </div>
  )
}
