import React, { useState } from "react"
import { observer } from "mobx-react"
import { Drawer, List, Hidden } from "@material-ui/core"
import { makeStyles, useTheme, Theme, createStyles } from "@material-ui/core/styles"
import { RoleType } from "eteva-types"
import { SidebarItem, Item } from "../nav"
import { ListItem } from "./ListItem"
import { useStores } from "../../../models/root-store"
import { color } from "../../../theme"
import { NavBar } from "./NavBar"
import EtevaLogo from "../../../static/eteva-white.png"

const { version: appVersion } = require("../../../../package.json")

export const DRAWER_WIDTH = 364

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    drawer: {
      [theme.breakpoints.up("md")]: {
        width: DRAWER_WIDTH,
        flexShrink: 0,
      },
    },
    content: {
      flexGrow: 1,
      padding: 30,
      marginLeft: DRAWER_WIDTH,
    },
    logoMobile: {
      width: "auto",
      height: 35,
      position: "absolute",
      left: "50%",
      transform: `translateX(-50%)`,
      [theme.breakpoints.up("md")]: {
        width: `calc(100% - ${DRAWER_WIDTH}px)`,
        display: "none",
      },
    },
    logo: {
      width: "auto",
      height: 20,
    },
    menu: {
      display: "flex",
      flexFlow: "column nowrap",
      justifyContent: "space-between",
      flex: 1,
      background: color.primary,
      width: DRAWER_WIDTH,
    },
    headerContainer: {
      width: DRAWER_WIDTH,
      zIndex: 9999,
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      height: 60,
      backgroundColor: color.primary,
      paddingLeft: 28,
      borderRadius: 0,
      borderBottom: `1px solid ${color.palette.transparentBlack}`,
    },
    version: {
      marginLeft: 20,
      marginBottom: 5,
    },
  })
)

interface SidebarProps {
  location?: Location
  menuItems: SidebarItem[]
}

const Sidebar = (props: SidebarProps) => {
  const { menu, drawer, headerContainer, logoMobile, logo, root, version } = useStyles()
  const theme = useTheme()
  const [mobileOpen, setMobileOpen] = useState(false)
  const { userStore } = useStores()
  const { currentUser } = userStore
  const { menuItems } = props

  const handleDrawerToggle = () => setMobileOpen(!mobileOpen)

  const renderItem = (item: Item) => <ListItem key={item.path} open={mobileOpen} item={item} />

  const renderMenuItems = () =>
    menuItems.map(item => (
      <List component="div" key={item.header} disablePadding>
        {item.items
          .filter(i => currentUser && i.allowedRoles.includes(currentUser.role as RoleType))
          .map(item => renderItem(item))}
      </List>
    ))

  const renderMenu = () => (
    <div className={menu}>
      <List component="nav" disablePadding>
        {renderMenuItems()}
      </List>
      {/* TODO: Find a proper way to do this because this is obviously completely stupid */}
      <span className={version}>{`v${appVersion}`}</span>
    </div>
  )

  const renderNavigation = () => (
    <nav>
      <Hidden mdUp implementation="css">
        <Drawer
          className={drawer}
          variant="temporary"
          anchor={theme.direction === "rtl" ? "right" : "left"}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <div className={headerContainer}>
            <img className={logoMobile} src={EtevaLogo} alt="logo" />
          </div>
          {renderMenu()}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer className={drawer} variant="permanent" open>
          <div className={headerContainer}>
            <img className={logo} src={EtevaLogo} alt="logo" />
          </div>
          {renderMenu()}
        </Drawer>
      </Hidden>
    </nav>
  )

  return (
    <div className={root}>
      <NavBar onClick={handleDrawerToggle} />
      {renderNavigation()}
    </div>
  )
}

export default observer(Sidebar)
