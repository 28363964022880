import { Typography, makeStyles } from "@material-ui/core"
import { IAppInfo } from "eteva-types"
import React, { FC, useEffect, useState } from "react"
import { Screen } from "../components"
import { FormSubmit, FormWysiwyg } from "../components/Form/FormFields"
import { useFormStyles } from "../components/Form/styles"
import { useStores } from "../models/root-store"

const useStyles = makeStyles({
  container: {
    padding: 10,
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    "@media (min-width:600px)": {
      padding: 50,
      display: "flex",
      flex: 1,
    },
  },
  heading: {
    marginTop: 20,
    marginBottom: 10,
  },
})

export const AppInfoScreen: FC = () => {
  const {
    appInfoStore: { getAppInfos, addAppInfo },
  } = useStores()
  const classes = useStyles()
  const formClasses = useFormStyles()
  const [appInfo, setAppInfo] = useState("")
  const [appInfoSv, setAppInfoSv] = useState("")

  const [help, setHelp] = useState("")
  const [helpSv, setHelpSv] = useState("")

  const handleGetAppInfos = async () => {
    const res: IAppInfo[] = await getAppInfos()
    if (res) {
      setAppInfo(res.find(it => it.title === "appInfo")?.content || "")
      setHelp(res.find(it => it.title === "help")?.content || "")
      setAppInfoSv(res.find(it => it.title === "appInfo-sv")?.content || "")
      setHelpSv(res.find(it => it.title === "help-sv")?.content || "")
    }
  }

  useEffect(() => {
    ;(async () => {
      await handleGetAppInfos()
    })()
  }, [getAppInfos])

  const handleChange = (field: string, val: string) => {
    if (field === "appInfo") setAppInfo(val)
    if (field === "help") setHelp(val)
    if (field === 'help-sv') setHelpSv(val)
    if (field === 'appInfo-sv') setAppInfoSv(val)
  }

  const handleAppInfoSubmit = async (language: 'fi' | 'sv') => {
    const content = language === 'fi' ? appInfo : appInfoSv;
    await addAppInfo({ title: language === 'fi' ? "appInfo" : "appInfo-sv", content: content, language: language });
    await handleGetAppInfos()
  }

  const handleHelpSubmit = async (language: 'fi' | 'sv') => {
    const content = language === 'fi' ? help : helpSv;
    await addAppInfo({ title: language === 'fi' ? "help" : "help-sv", content: content, language: language });
    await handleGetAppInfos();
  };
  
  return (
    <Screen headerTitle="screens.appInfo">
      <div className={classes.container}>
        <form className={formClasses.formContainer}>
          <div className={formClasses.inputContainer}>
            <Typography variant="h5" className={classes.heading}>
              Suomeksi
            </Typography>
            <FormWysiwyg
              field={{ id: "appInfo", label: "appInfos.appInfo", type: "wysiwyg", value: appInfo }}
              onChange={handleChange}
              value={appInfo}
            />
            <FormSubmit
              field={{ addLabel: "appInfos.saveAppInfo", updateLabel: "appInfos.saveAppInfo" }}
              isDisabled={false}
              onSubmit={() => handleAppInfoSubmit('fi')}
            />
            <FormWysiwyg
              field={{ id: "help", label: "appInfos.help", type: "wysiwyg", value: help }}
              onChange={handleChange}
              value={help}
            />
            <FormSubmit
              field={{ addLabel: "appInfos.saveHelp", updateLabel: "appInfos.saveHelp" }}
              isDisabled={false}
              onSubmit={() => handleHelpSubmit('fi')}
            />
            <Typography variant="h5" className={classes.heading}>
              Ruotsiksi
            </Typography>
            <FormWysiwyg
              field={{ id: "appInfo-sv", label: "appInfos.appInfo", type: "wysiwyg", value: appInfoSv }}
              onChange={handleChange}
              value={appInfoSv}
            />
            <FormSubmit
              field={{ addLabel: "appInfos.saveAppInfo", updateLabel: "appInfos.saveAppInfo" }}
              isDisabled={false}
              onSubmit={() => handleAppInfoSubmit('sv')}
            />
            <FormWysiwyg
              field={{ id: "help-sv", label: "appInfos.help", type: "wysiwyg", value: helpSv }}
              onChange={handleChange}
              value={helpSv}
            />
            <FormSubmit
              field={{ addLabel: "appInfos.saveHelp", updateLabel: "appInfos.saveHelp" }}
              isDisabled={false}
              onSubmit={() => handleHelpSubmit('sv')}
            />
          </div>
        </form>
      </div>
    </Screen>
  )
}
