import { RootPaths } from "../../routers/Paths"
import { Roles, RoleType } from "eteva-types"
import UserManagement from "../../static/people.svg"
import UserGroups from "../../static/ios-star.svg"
import Categories from "../../static/category.svg"
import Content from "../../static/writing.svg"
import DayProgram from "../../static/trophy.svg"
import AppInfo from "../../static/ios-settings.svg"
import Analytics from "../../static/writing.svg"

export interface SidebarItem {
  header: string
  items: Item[]
}
export interface Item {
  title: string
  icon: string
  activeIcon: string
  path: string
  allowedRoles: RoleType[]
}

export const SidebarItems = [
  {
    header: "general",
    items: [
      {
        title: "users",
        icon: UserManagement,
        activeIcon: UserManagement,
        path: RootPaths.users,
        allowedRoles: [Roles.SUPERADMIN],
      },
      {
        title: "userGroups",
        icon: UserGroups,
        activeIcon: UserGroups,
        path: RootPaths.userGroups,
        allowedRoles: [Roles.SUPERADMIN, Roles.ADMIN],
      },
      {
        title: "categories",
        icon: Categories,
        activeIcon: Categories,
        path: RootPaths.categories,
        allowedRoles: [Roles.SUPERADMIN, Roles.ADMIN, Roles.EDITOR],
      },
      {
        title: "subCategories",
        icon: Categories,
        activeIcon: Categories,
        path: RootPaths.subCategories,
        allowedRoles: [Roles.SUPERADMIN, Roles.ADMIN, Roles.EDITOR],
      },
      {
        title: "contents",
        icon: Content,
        activeIcon: Content,
        path: RootPaths.contents,
        allowedRoles: [Roles.SUPERADMIN, Roles.ADMIN, Roles.EDITOR],
      },
      {
        title: "dayPrograms",
        icon: DayProgram,
        activeIcon: DayProgram,
        path: RootPaths.dayPrograms,
        allowedRoles: [Roles.SUPERADMIN, Roles.ADMIN, Roles.EDITOR],
      },
      {
        title: "analytics",
        icon: Analytics,
        activeIcon: Analytics,
        path: RootPaths.analytics,
        allowedRoles: [Roles.SUPERADMIN, Roles.ADMIN, Roles.EDITOR],
      },
      {
        title: "appInfo",
        icon: AppInfo,
        activeIcon: AppInfo,
        path: RootPaths.appInfo,
        allowedRoles: [Roles.SUPERADMIN],
      },
    ],
  },
]
