import React, { useState, useEffect, useCallback, KeyboardEvent } from "react"
import { observer } from "mobx-react"
import { FilterButtons, Table, TableProperty } from "../../components"
import { useStores } from "../../models/root-store"
import { CommonAttributes } from "eteva-types"
import { DayProgramModelType } from "../../models/day-program-store"
import { useTranslation } from "react-i18next"
import { makeStyles } from "@material-ui/styles"
import { SearchBar } from "../Layout/SearchBar"

const tableProperties: TableProperty<DayProgramModelType>[] = [
  { key: "title", title: "dayPrograms.title" },
  { key: "likes", title: "dayPrograms.likes" },
  { key: "dislikes", title: "dayPrograms.dislikes" },
  { key: "hits", title: "dayPrograms.hits" },
]

const useStyles = makeStyles({
  filterContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    margin: "26px 0",
  },
})

const filterButtons = [
  { value: true, label: "categories.activeFilter" },
  { value: false, label: "categories.inactiveFilter" },
]

export const DayProgramAnalytics: React.FC = observer(() => {
  const {
    dayProgramStore: { dayPrograms, total, fetching, getAll },
  } = useStores()
  const { t } = useTranslation()
  const { filterContainer } = useStyles()
  const [search, setSearch] = useState("")
  const [selectedRadio, setSelectedRadio] = useState<boolean | undefined>()
  const [currentPage, setCurrentPage] = useState(0)
  const pageSize = 10

  const getAllDayPrograms = async (page = 0) => {
    if (!page) setCurrentPage(0)
    await getAll(page, pageSize, search, selectedRadio)
  }

  const memoizedGetDayPrograms = useCallback(getAllDayPrograms, [])

  useEffect(() => {
    memoizedGetDayPrograms()
  }, [memoizedGetDayPrograms])

  const onChangePage = (event: any, page: number) => {
    if (total > pageSize * page || page > -1) {
      setCurrentPage(page)
      getAll(page, pageSize, search, selectedRadio)
    }
  }

  const handleSearch = (e: KeyboardEvent<HTMLInputElement>) => {
    const searchQuery = (e.target as HTMLInputElement).value || ""
    setSearch(searchQuery)
    setCurrentPage(0)
    getAll(0, pageSize, searchQuery, selectedRadio)
  }

  const onRadioClick = (value: boolean) => {
    setSelectedRadio(value)
    setCurrentPage(0)
    getAll(0, pageSize, search, value)
  }

  const renderFilters = () => (
    <div className={filterContainer}>
      <FilterButtons
        onRadioClick={onRadioClick}
        buttons={filterButtons}
        selected={selectedRadio}
        defaultChecked="super"
      />
      <SearchBar width="100%" onChange={handleSearch} placeholder="categories.searchByName" />
    </div>
  )

  return (
    <>
      <h3>{t("sidebar.dayPrograms")}</h3>
      {renderFilters()}
      <Table<DayProgramModelType & CommonAttributes>
        hideCheckbox
        loading={fetching}
        total={total}
        page={currentPage}
        pageSize={pageSize}
        onChangePage={onChangePage}
        items={dayPrograms}
        properties={tableProperties}
      />
    </>
  )
})
