import React, { useState, useEffect } from "react"
import { useHistory, useParams } from "react-router-dom"
import { observer } from "mobx-react"
import { CommonAttributes, ICategory } from "eteva-types"
import { makeStyles } from "@material-ui/core"
import { Screen, Loader, Form } from "../../components"
import { useStores } from "../../models/root-store"
import { FormSection, FormSelectField } from "../../components/Form/FormTypes"
import { CategoryWithPreview } from "../../utils/interfaces"
import { formatFormData } from "../../utils"
// import { ModificationDetails } from "../../components/ModificationDetails"
import { RootPaths } from "../../routers/Paths"

const useStyles = makeStyles({
  container: {
    padding: 10,
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    "@media (min-width:600px)": {
      padding: 50,
      display: "flex",
      flex: 1,
    },
  },
})

interface CategoryScreenProps {}

export const CategoryScreen: React.FC<CategoryScreenProps> = observer(() => {
  const {
    categoryStore: { fetching, getOne, create, update },
    userGroupStore: { fetching: fetchingUserGroups, getAll: getUserGroups, asSelectOptions: userGroups },
    formStore: { setSectionDirty },
  } = useStores()
  const [categoryState, setCategoryState] = useState<Partial<CategoryWithPreview & CommonAttributes>>({})
  const { container } = useStyles()
  const history = useHistory()
  const { id } = useParams<{ id: string }>()

  const getCategoryWithId = async (id: number) => {
    const newCategory: ICategory = await getOne(id)
    if (newCategory) {
      setCategoryState({
        ...newCategory,
        userGroups: newCategory.userGroups?.map(it => it.id) || [],
      })
    }
  }

  useEffect(() => {
    ;(async () => {
      if (id && !isNaN(Number(id))) await getCategoryWithId(Number(id))
      await getUserGroups(0, 1000)
    })()
  }, [id])

  const handleSubmit = async (state: CategoryWithPreview) => {
    setSectionDirty()

    const formattedState = {
      ...state,
      userGroups: state.userGroups?.map(it => ({
        id: it,
      })),
    }

    const submitForm = new FormData()
    const formData = { ...formatFormData(formattedState) }
    submitForm.append("data", JSON.stringify(formData))
    if (state.imageFile) submitForm.append("image", state.imageFile)

    let success = false
    if (state.id) {
      success = await update(submitForm)
    } else {
      success = await create(submitForm)
    }

    if (success) history.push(RootPaths.categories)
  }

  const handleCancel = () => history.push(RootPaths.categories)

  const header = id ? "categories.edit" : "categories.add"

  const getFormFields = (type: string, title: string) =>
    ({
      type,
      title,
      fields: [
        {
          id: "title",
          type: "input",
          label: "form.title",
          required: true,
        },
        {
          id: "imageFile",
          type: "image",
          label: "form.image",
          required: true,
        },
        {
          id: "userGroups",
          type: "select",
          label: "form.userGroups",
          options: userGroups(),
          multiple: true,
          loading: fetchingUserGroups,
          required: true,
        } as FormSelectField,
        {
          id: "help",
          type: "help",
          label: "form.help",
        },
        {
          id: "submit",
          type: "submit",
          label: "submit",
          addLabel: "form.save",
          updateLabel: "form.update",
        },
      ],
    } as FormSection)

  const renderContent = () => {
    if (fetching) return <Loader />
    return (
      <div className={container}>
        <Form
          content={categoryState}
          formId="category"
          section={getFormFields("category", "")}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
        />
        {/* <ModificationDetails item={categoryState} /> */}
      </div>
    )
  }
  return <Screen headerTitle={header}>{renderContent()}</Screen>
})
