import React, { useState, useEffect } from "react"
import { observer } from "mobx-react"
import { makeStyles } from "@material-ui/core"
import { IUser, CommonAttributes, Roles } from "eteva-types"
import { Screen, Loader, Form } from "../components"
import { color } from "../theme"
import { useHistory, useParams } from "react-router-dom"
import { useStores } from "../models/root-store"
// import { ModificationDetails } from "../components/ModificationDetails"
import { FormSection, FormSelectField } from "../components/Form/FormTypes"

const useStyles = makeStyles({
  container: {
    padding: 10,
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    "@media (min-width:600px)": {
      padding: 50,
      display: "flex",
      flex: 1,
    },
  },
  modified: {
    fontFamily: "Bw Surco",
    fontSize: 16,
    marginTop: 0,
    color: color.dim,
    marginBottom: 10,
  },
  date: {
    fontSize: 20,
    fontWeight: 300,
    marginBottom: 10,
  },
  createdContainer: {
    marginTop: 40,
  },
  modifiedContainer: {
    marginLeft: 40,
  },
})

interface UserScreenProps {}

export const UserScreen: React.FC<UserScreenProps> = observer(() => {
  const {
    userStore: { fetching, getUser, updateUser, addUser },
  } = useStores()
  const [userState, setUserState] = useState<(IUser & CommonAttributes) | undefined>()
  const { container } = useStyles()
  const history = useHistory()
  const { id } = useParams<{ id: string }>()

  const getExistingUser = async (id: number) => {
    const newUser = await getUser(id)
    if (newUser) {
      setUserState(newUser)
    }
  }

  useEffect(() => {
    ;(async () => {
      if (id && !isNaN(Number(id))) await getExistingUser(Number(id))
    })()
  }, [id])

  const handleCancel = () => {
    history.push("/kayttajat")
  }

  const handleSubmit = async (state: IUser) => {
    let success = false
    if (state && state.id) {
      success = await updateUser({
        id: state.id,
        email: state.email,
        role: state.role,
      } as IUser)
    } else {
      success = await addUser({
        email: state.email,
        role: state.role,
      } as IUser)
    }
    if (success) history.push("/kayttajat")
  }

  const header = id ? "users.edit" : "users.add"

  const roles = [
    { label: "users.roles.superAdmin", value: Roles.SUPERADMIN },
    { label: "users.roles.admin", value: Roles.ADMIN },
    { label: "users.roles.editor", value: Roles.EDITOR },
  ]

  const getFormFields = (type: string, title: string) =>
    ({
      type,
      title,
      fields: [
        {
          id: "email",
          type: "input",
          label: "form.email",
          required: true,
        },
        {
          id: "role",
          type: "select",
          label: "form.role",
          options: roles,
          required: true,
        } as FormSelectField,
        {
          id: "submit",
          type: "submit",
          label: "submit",
          addLabel: "form.save",
          updateLabel: "form.update",
        },
      ],
    } as FormSection)

  const renderContent = () => {
    if (fetching) return <Loader />
    return (
      <div className={container}>
        <Form
          content={userState}
          formId="category"
          section={getFormFields("category", "")}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
        />
        {/* <ModificationDetails item={userState} /> */}
      </div>
    )
  }
  return <Screen headerTitle={header}>{renderContent()}</Screen>
})
