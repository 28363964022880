import { RootStore } from "./../root-store/root-store"
import { api } from "./../../services/api"
import { Instance, SnapshotOut, types as t, flow, getParent, cast } from "mobx-state-tree"
import { FlowType } from "eteva-rest-api"
import { IAppInfo, AppInfoTitleModel } from "eteva-types"
import { omit } from "ramda"
import { withEnvironment } from "../extensions"

const AppInfoModel = t.model("AppInfosModel", {
  id: t.identifierNumber,
  title: t.string,
  content: t.maybeNull(t.string),
  language: t.string
})

export type AppInfoModelType = Instance<typeof AppInfoModel>

export const AppInfoStoreModel = t
  .model("AppInfoStore")
  .props({
    appInfos: t.maybe(t.array(AppInfoModel)),
    fetching: false,
  })
  .extend(withEnvironment)
  .actions(self => ({
    getAppInfos: flow(function* (): FlowType {
      self.fetching = true
      const result = yield api.appInfo.getAll()
      self.fetching = false
      if (result) {
        self.appInfos = cast(result)
        return result
      }
      return null
    }),
    getAppInfoByTitle: flow(function* (title: AppInfoTitleModel): FlowType {
      self.fetching = true
      const result: AppInfoModelType = yield api.appInfo.getByTitle(title)
      self.fetching = false
      if (result) {
        self.appInfos = cast(self.appInfos?.filter(it => it.id !== result.id).concat([result]))
        return true
      }
      return false
    }),
  }))
  .actions(self => ({
    addAppInfo: flow(function* (appInfo: IAppInfo): FlowType {
      self.fetching = true
      const { messageStore } = getParent(self) as RootStore
      const result: boolean = yield api.appInfo.create(appInfo)
      self.fetching = false
      if (result) {
        messageStore.setMessage("message.success.appInfo.added", "success")
        return true
      }
      messageStore.setMessage("message.error.appInfo.emailInUse", "error")
      return false
    }),
    updateAppInfo: flow(function* (appInfo: IAppInfo) {
      self.fetching = true
      const { messageStore } = getParent(self) as RootStore
      const result = yield api.appInfo.update(appInfo)
      self.fetching = false
      if (result) {
        messageStore.setMessage("message.success.appInfo.updated", "success")
        return true
      }
      messageStore.setMessage("message.error.common", "error")
      return false
    }),
  }))
  .postProcessSnapshot(omit(["fetching"]))
/**
  * Un-comment the following to omit model attributes from your snapshots (and from async storage).
  * Useful for sensitive data like passwords, or transitive state like whether a modal is open.

  * Note that you'll need to import `omit` from ramda, which is already included in the project!
  */

type AppInfoStoreType = Instance<typeof AppInfoStoreModel>
export interface AppInfoStore extends AppInfoStoreType {}
type AppInfoStoreSnapshotType = SnapshotOut<typeof AppInfoStoreModel>
export interface AppInfoStoreSnapshot extends AppInfoStoreSnapshotType {}
