import { RestApi } from "eteva-rest-api"
import { ImageRequestModel } from "eteva-types"

const TOKEN_S = "token"

export class Api extends RestApi {
  rememberMe = true

  saveToken(token: string): void {
    if (this.rememberMe) {
      localStorage.setItem(TOKEN_S, token)
    }
  }

  loadToken(): string | null | undefined {
    return localStorage.getItem(TOKEN_S)
  }

  cleanUserData(): void {
    localStorage.clear()
  }

  async getImage({ id, width, height, fit }: ImageRequestModel): Promise<Blob | null> {
    const res = await this.httpClient.get(
      `/images/${id}?width=${width}&height=${height}&fit=${fit}`,
      {},
      { responseType: "blob" }
    )
    if (res.ok) return res.data as Blob
    return null
  }

  getImageUri({ id, width, height, fit }: ImageRequestModel): string {
    let string = ""
    string = string.concat(`${this.httpClient.getBaseURL()}/images/${id}?`)
    if (width) string = string.concat(`width=${width}`)
    if (height) string = string.concat(`&height=${height}`)
    if (fit) string = string.concat(`&fit=${fit}`)
    return string
  }
}

export const api = new Api()
