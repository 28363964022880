import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { color } from "../theme"
import { useTranslation } from "react-i18next"
import { Button } from "./Button"
import Modal from "@material-ui/core/Modal"
import { Text } from "./Text"

const useStyles = makeStyles({
  container: {
    "@media (min-width:600px)": {
      minWidth: 326,
      maxWidth: 500,
      minHeight: 200,
      position: "absolute",
      backgroundColor: color.palette.white,
      borderRadius: 2,
      padding: "30px 40px",
      display: "flex",
      flexFlow: "column nowrap",
      flex: "0 1 auto",
      boxShadow: "none",
      "&:focus": {
        outline: "none",
      },
    },
    minWidth: 326,
    maxWidth: 500,
    minHeight: 200,
    position: "absolute",
    backgroundColor: color.palette.white,
    borderRadius: 2,
    padding: 20,
    display: "flex",
    flexFlow: "column nowrap",
    flex: "0 1 auto",
    boxShadow: "none",
    "&:focus": {
      outline: "none",
    },
  },
  header: {
    fontSize: 24,
    fontWeight: "bold",
    marginTop: 0,
    marginBottom: 5,
    fontFamily: "Bw Surco",
  },
  textContainer: {
    fontFamily: "Bw Surco",
    marginBottom: 40,
    display: "flex",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
  },
  button: {
    marginLeft: 12,
    marginRight: 12,
  },
  buttonText: {
    fontSize: 18,
    fontFamily: "Bw Surco",
    textTransform: "none",
    padding: "0px 20px",
  },
  deleteButtonText: {
    fontSize: 18,
    fontFamily: "Bw Surco",
    textTransform: "none",
    color: color.palette.white,
    padding: "0px 30px",
  },
  cancelText: {
    marginRight: 24,
  },
  progress: {
    color: color.palette.white,
  },
  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
})

interface ModalDialogProps {
  isOpen: boolean
  onLeftClick(): void
  onRightClick(): void
  title?: string
  text?: string
  leftButtonText?: string
  rightButtonText?: string
  deleteButton?: boolean
  children?: React.ReactNode
}

const ModalDialog: React.FC<ModalDialogProps> = props => {
  const classes = useStyles()
  const { t } = useTranslation()
  const {
    isOpen,
    onLeftClick,
    onRightClick,
    title = "",
    text = "",
    leftButtonText = "",
    rightButtonText = "",
    children,
  } = props

  const renderModalContent = () => {
    if (children) {
      return children
    } else {
      return <p className={classes.textContainer}>{t(text)}</p>
    }
  }

  const renderContent = () => {
    return (
      <div className={classes.container}>
        <h3 className={classes.header}>{t(title)}</h3>
        {renderModalContent()}
        <div className={classes.buttonContainer}>
          <Button className={classes.button} onClick={onLeftClick} primary>
            <Text preset="button" tx={leftButtonText} />
          </Button>
          <Button className={classes.button} onClick={onRightClick}>
            <Text preset="button" tx={rightButtonText} />
          </Button>
        </div>
      </div>
    )
  }
  return (
    <Modal className={classes.modal} open={isOpen} onClose={onLeftClick}>
      {renderContent()}
    </Modal>
  )
}

export default ModalDialog
