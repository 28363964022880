import React, { FC } from "react"
import { ContentSectionWithPreview, DayProgramSectionWithPreview, ImageWithPreview } from "../../utils/interfaces"
import { Image } from "../Image"
import { Text } from "../Text"
import { Edit, Close, ArrowUpward, ArrowDownward } from "@material-ui/icons"
import { useRepeaterStyles } from "./styles"
import { Button } from "../Button"

interface ContentSectionProps {
  contentSection: Partial<ContentSectionWithPreview>
  onRemove: (section: any) => void
  onEdit: (section: Partial<ContentSectionWithPreview>) => void
  onMoveDown: (section: Partial<ContentSectionWithPreview>) => void
  onMoveUp: (section: Partial<ContentSectionWithPreview>) => void
}
const ContentSection: FC<ContentSectionProps> = ({ contentSection, onRemove, onEdit, onMoveUp, onMoveDown }) => {
  const classes = useRepeaterStyles()
  const { content } = contentSection

  const handleEdit = (e: any) => {
    e.preventDefault()
    if (onEdit) onEdit(contentSection)
  }

  const handleRemove = (e: any) => {
    e.preventDefault()
    onRemove(contentSection)
  }

  const handlePressUp = (e: any) => {
    e.preventDefault()
    onMoveUp(contentSection)
  }

  const handlePressDown = (e: any) => {
    e.preventDefault()
    onMoveDown(contentSection)
  }

  const getYoutubeCode = () => {
    if (!content) return ""
    if (content.indexOf("iframe") >= 0) return content.split("/embed/")[1].split('"')[0]
    if (content.indexOf("youtu.be") >= 0) return content.split(".be/")[1].split("?t")[0]
    if (content.indexOf("youtube.com") >= 0) return content.split("watch?v=")[1].split("&t")[0]
    return ""
  }

  const getYoutubeTimestamp = () => {
    if (!content) return ""
    if (content.indexOf("t=") >= 0) return `?start=${content.split("t=")[1].split("s")[0]}&rel=0`
    if (content.indexOf("iframe") >= 0) return "&rel=0"
    return "?rel=0"
  }

  const renderImage = () => {
    const contentSectionImage = contentSection as ImageWithPreview
    if (!contentSection.id) {
      return <Image className={classes.imgPreview} source={contentSectionImage.imagePreviewUrl as string} />
    }
    return (
      <Image
        className={classes.imgPreview}
        imageId={contentSection.imageId}
        source={contentSectionImage.imagePreviewUrl as string}
      />
    )
  }

  const renderButtons = () => (
    <div className={classes.sectionButtonContainer}>
      <button className={classes.sectionButton} onClick={handlePressDown}>
        <ArrowDownward className={classes.icon} />
      </button>
      <button className={classes.sectionButton} onClick={handlePressUp}>
        <ArrowUpward className={classes.icon} />
      </button>
      <button className={classes.sectionButton} onClick={handleEdit}>
        <Edit className={classes.editIcon} />
      </button>
      <button className={classes.sectionButton} onClick={handleRemove}>
        <Close className={classes.icon} />
      </button>
    </div>
  )

  const renderContent = () => {
    switch (contentSection.type) {
      case "image":
        return renderImage()
      case "link":
        return (
          <Button>
            <Text text={contentSection.linkText ?? contentSection.content} />
          </Button>
        )
      case "wysiwyg":
        return (
          <div
            className={classes.wysiwyg}
            dangerouslySetInnerHTML={{ __html: (contentSection.content as string) || "" }}
          />
        )
      case "audio":
        return (
          <Button>
          <Text text={contentSection.content ?? 'Ääni'} />
          </Button>
        )
      case "gif":
        return (
          <Button>
          <Text text={contentSection.content ?? "Gif"} />
          </Button>
        )
      case "youtube":
        return (
          <div className={classes.youtubeWrapper}>
            <iframe
              className={classes.iframe}
              src={`https://www.youtube.com/embed/${getYoutubeCode()}${getYoutubeTimestamp()}`}
              width="100%"
              height="100%"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        )
      case "dreambroker":
        return (
          <div
            className={classes.youtubeWrapper}
            dangerouslySetInnerHTML={{
              __html:
                (contentSection.content
                  ?.replace(`width="1280"`, `width="100%"`)
                  .replace(`height="720"`, `height="100%"`)
                  .replace("<iframe", `<iframe class="${classes.iframe}"`) as string) || "",
            }}
          />
        )
      default:
        return null
    }
  }

  return (
    <div className={classes.container}>
      {renderButtons()}
      {renderContent()}
    </div>
  )
}

interface FormContentSectionProps {
  contentSections: Partial<ContentSectionWithPreview>[]
  onRemove: (section: ContentSectionWithPreview) => void
  onEditClick: (section: Partial<ContentSectionWithPreview>) => void
  onMoveUpClick: (section: Partial<ContentSectionWithPreview>) => void
  onMoveDownClick: (section: Partial<ContentSectionWithPreview>) => void
}
export const FormContentSections: FC<FormContentSectionProps> = ({
  onRemove,
  contentSections,
  onEditClick,
  onMoveUpClick,
  onMoveDownClick,
}) => {
  const removePreparer = (section: ContentSectionWithPreview) => onRemove(section)
  if (!contentSections?.length) return null

  return (
    <>
      {contentSections
        .sort((a, b) => (a.position && b.position ? a.position - b.position : 0))
        .map((cs: Partial<ContentSectionWithPreview>, i: number) => (
          <ContentSection
            key={i}
            contentSection={cs}
            onRemove={removePreparer}
            onEdit={onEditClick}
            onMoveUp={onMoveUpClick}
            onMoveDown={onMoveDownClick}
          />
        ))}
    </>
  )
}

interface FormDayProgramSectionProps {
  sections: Partial<DayProgramSectionWithPreview>[]
  onRemove: (section: DayProgramSectionWithPreview) => void
  onEditClick: (section: Partial<DayProgramSectionWithPreview>) => void
  onMoveUpClick: (section: Partial<DayProgramSectionWithPreview>) => void
  onMoveDownClick: (section: Partial<DayProgramSectionWithPreview>) => void
}
export const FormDayProgramSections: FC<FormDayProgramSectionProps> = ({
  onRemove,
  sections,
  onEditClick,
  onMoveUpClick,
  onMoveDownClick,
}) => {
  const classes = useRepeaterStyles()

  const removePreparer = (section: DayProgramSectionWithPreview) => onRemove(section)
  if (!sections?.length) return null

  const listSections = [...new Set(sections.map(it => it.section).sort((a, b) => (a && b ? a - b : 0)))]

  const renderSections = () =>
    listSections.map(sectionNumber => {
      return (
        <div key={sectionNumber} className={classes.section}>
          <Text text={`${sectionNumber}.`} className={classes.sectionNumber} />
          {sections
            .filter(s => s.section === sectionNumber)
            .sort((a, b) => (a.position && b.position ? a.position - b.position : 0))
            .map((cs: Partial<DayProgramSectionWithPreview>, i: number) => (
              <ContentSection
                key={i}
                contentSection={cs}
                onRemove={removePreparer}
                onEdit={onEditClick}
                onMoveUp={onMoveUpClick}
                onMoveDown={onMoveDownClick}
              />
            ))}
        </div>
      )
    })

  return <>{renderSections()}</>
}
