import { types as t, Instance } from "mobx-state-tree"
import { FormModel } from "./FormModel"
import { omit } from "ramda"
import { values } from "mobx"

export const FormStore = t
  .model("FormStore", {
    forms: t.map(FormModel),
    isSectionDirty: false,
    isErrorsVisible: false,
  })
  .views(self => ({
    // Returns an array of invalid forms. Used e.g. in TabContainer
    getInvalidForms(): string[] {
      const invalidForms = values(self.forms)
        .map((form: any) => {
          const errors = values(form.errors).filter(error => error)
          if (errors.length) return form.name
          return null
        })
        .filter(form => form)

      return invalidForms
    },
    // Get a single validation error, used e.g. in Editor
    getError(formId: string, fieldId: string) {
      const form = self.forms.get(formId)
      if (!form) return null
      return form.errors.get(fieldId)
    },
  }))
  .actions(self => ({
    // Register a single form
    registerForm(formId: string): any {
      if (self.forms.get(formId)) return
      self.forms.set(formId, { name: formId, errors: {} })
    },
  }))
  .actions(self => ({
    setSectionDirty() {
      self.isSectionDirty = true
    },

    // Display validation errors
    showErrors() {
      self.isErrorsVisible = true
    },

    // Hide validation errors
    hideErrors() {
      self.isErrorsVisible = false
    },

    // Set section pristine (not dirty)
    setSectionPristine() {
      self.isSectionDirty = false
    },

    registerForms(formIds: string[]) {
      formIds.map(formId => self.registerForm(formId))
    },

    // Unregister all forms
    unregisterForms() {
      self.forms.clear()
      self.isSectionDirty = false
      self.isErrorsVisible = false
    },
    // Validates a field based on formId and fieldId. If there's not msg, there's no error
    validate(formId: any, fieldId: string, msg?: string): any {
      const form = self.forms.get(formId)
      if (!form) throw new TypeError("Validate: Form is not registered.")

      // Error is set only if a message is defined, otherwise we delete the error
      if (msg) {
        form.errors.set(fieldId, msg)
        return
      }
      form.errors.delete(fieldId)
    },
  }))
  .postProcessSnapshot(omit(["forms", "isSectionDirty", "isErrorsVisible"]))

export interface FormStore extends Instance<typeof FormStore> {}
