import React, { useState, useEffect } from "react"
import { useHistory, useParams } from "react-router-dom"
import { observer } from "mobx-react"
import { CommonAttributes, IContent } from "eteva-types"
import { makeStyles } from "@material-ui/core"
import { Screen, Loader, Form } from "../../components"
import { useStores } from "../../models/root-store"
import { FormSection, FormSelectField } from "../../components/Form/FormTypes"
import { ContentWithPreview } from "../../utils/interfaces"
import { formatFormData } from "../../utils"
// import { ModificationDetails } from "../../components/ModificationDetails"
import { RootPaths } from "../../routers/Paths"

const useStyles = makeStyles({
  container: {
    padding: 10,
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    "@media (min-width:600px)": {
      padding: 50,
      display: "flex",
      flex: 1,
    },
  },
})

interface ContentScreenProps {}

export const ContentScreen: React.FC<ContentScreenProps> = observer(() => {
  const {
    contentStore: { fetching, getOne, create, update, submitSections, clearCurrentSections },
    categoryStore: { fetching: fetchingCategories, getAll: getCategories, asSelectOptions: categories },
    subCategoryStore: { fetching: fetchingSubCategories, getAll: getSubCategories, asSelectOptions: subCategories },
  } = useStores()
  const [contentState, setContentState] = useState<Partial<ContentWithPreview & CommonAttributes>>({})
  const { container } = useStyles()
  const history = useHistory()
  const { id } = useParams<{ id: string }>()

  const getContentWithId = async (id: number) => {
    const newContent: IContent = await getOne(id)
    if (newContent) {
      setContentState({
        ...newContent,
        categories: newContent.categories?.map(it => it.id) || [],
        subCategories: newContent.subCategories?.map(it => it.id) || [],
      })
    }
  }

  useEffect(() => {
    return () => {
      clearCurrentSections()
    }
  }, [])

  useEffect(() => {
    ;(async () => {
      if (id && !isNaN(Number(id))) await getContentWithId(Number(id))
      await getCategories(0, 1000)
      await getSubCategories(0, 1000)
    })()
  }, [id])

  const handleSubmit = async (state: ContentWithPreview) => {
    const submitForm = new FormData()
    const formattedState = {
      ...state,
      categories: state.categories?.map(it => ({
        id: it,
      })),
      subCategories: state.subCategories?.map(it => ({
        id: it,
      })),
    }

    delete formattedState.sections

    const formData = { ...formatFormData(formattedState) }
    submitForm.append("data", JSON.stringify(formData))
    if (state.imageFile) submitForm.append("image", state.imageFile)

    let success = false
    let newContentId = undefined
    if (state.id) {
      success = await update(submitForm)
    } else {
      newContentId = await create(submitForm)
      success = Boolean(newContentId)
    }

    if (success && state.sections) {
      const doubleSuccess = await submitSections(state.sections, state.id || newContentId)
      if (doubleSuccess) {
        history.push(RootPaths.contents)
      }
    }
  }

  const handleCancel = () => history.push(RootPaths.contents)

  const header = id ? "contents.edit" : "contents.add"

  const getFormFields = (type: string, title: string) =>
    ({
      type,
      title,
      fields: [
        {
          id: "title",
          type: "input",
          label: "form.title",
          required: true,
        },
        {
          id: "imageFile",
          type: "image",
          label: "form.image",
          required: true,
        },
        {
          id: "categories",
          type: "select",
          label: "form.categories",
          options: categories(),
          multiple: true,
          loading: fetchingCategories,
          required: true,
        } as FormSelectField,
        {
          id: "subCategories",
          type: "select",
          label: "form.subCategories",
          options: subCategories(),
          multiple: true,
          loading: fetchingSubCategories,
          required: false,
        } as FormSelectField,
        {
          id: "help",
          type: "help",
          label: "form.help",
        },
        {
          id: "sections",
          type: "contentSection",
          label: "form.sections",
          required: true,
        },
        {
          id: "submit",
          type: "submit",
          label: "submit",
          addLabel: "form.save",
          updateLabel: "form.update",
        },
      ],
    } as FormSection)

  const renderContent = () => {
    if (fetching) return <Loader />
    return (
      <div className={container}>
        <Form
          content={contentState}
          formId="contents"
          section={getFormFields("contents", "")}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
        />
        {/* <ModificationDetails item={contentState} /> */}
      </div>
    )
  }
  return <Screen headerTitle={header}>{renderContent()}</Screen>
})
