import { RootStore } from "./../root-store/root-store"
import { Instance, SnapshotOut, types as t, flow, getParent, cast } from "mobx-state-tree"
import { FlowType } from "eteva-rest-api"
import { IUserGroup, PageResponse } from "eteva-types"
import { api } from "./../../services/api"
import { omit } from "ramda"
import { withEnvironment } from "../extensions"
import { SelectInputOption } from "../../components/SelectInput"

export const UserGroupModel = t.model("UserGroupModel", {
  id: t.identifierNumber,
  title: t.string,
  language: t.string,
  description: t.string,
  imageId: t.maybeNull(t.number),
  active: t.maybe(t.boolean),
})

export type UserGroupModelType = Instance<typeof UserGroupModel>

export const UserGroupStoreModel = t
  .model("UserGroupStore")
  .props({
    userGroups: t.optional(t.array(UserGroupModel), []),
    total: t.optional(t.number, 0),
    fetching: t.optional(t.boolean, false),
  })
  .extend(withEnvironment)
  .views(self => ({
    asSelectOptions: (): SelectInputOption[] => {
      return self.userGroups.map(it => ({ value: it.id, label: it.title }))
    },
  }))
  .actions(self => ({
    getAll: flow(function* (page: number, pageSize: number, search?: string, active?: boolean): FlowType {
      const { messageStore } = getParent(self) as RootStore
      self.fetching = true
      const response: PageResponse<IUserGroup> = yield api.userGroups.getAll({ page, pageSize, search }, active)
      self.fetching = false
      if (response) {
        self.userGroups = cast(response.results)
        self.total = response.total
        return true
      }
      messageStore.setMessage("message.error.common", "error")
      return false
    }),
    getOne: flow(function* (userGroupId): FlowType {
      const { messageStore } = getParent(self) as RootStore
      self.fetching = true
      const result = yield api.userGroups.getOne(userGroupId)
      self.fetching = false
      if (result) {
        return result
      }
      messageStore.setMessage("message.error.common", "error")
      return null
    }),
    create: flow(function* (userGroup: FormData): FlowType {
      const { messageStore } = getParent(self) as RootStore
      self.fetching = true
      const result: boolean = yield api.userGroups.create(userGroup)
      self.fetching = false
      if (result) {
        messageStore.setMessage("message.success.userGroup.added", "success")
        return true
      }
      messageStore.setMessage("message.error.common", "error")
      return false
    }),
    update: flow(function* (userGroup: FormData): FlowType {
      const { messageStore } = getParent(self) as RootStore
      self.fetching = true
      const result: boolean = yield api.userGroups.update(userGroup)
      self.fetching = false
      if (result) {
        messageStore.setMessage("message.success.userGroup.updated", "success")
        return true
      }
      messageStore.setMessage("message.error.common", "error")
      return false
    }),
    delitb: flow(function* (userGroupId): FlowType {
      const { messageStore } = getParent(self) as RootStore
      self.fetching = true
      const result = yield api.userGroups.delete(userGroupId)
      self.fetching = false
      if (result) {
        messageStore.setMessage("message.success.userGroup.deleted", "success")
        return true
      }
      messageStore.setMessage("message.error.common", "error")
      return null
    }),
    changeItemStatuses: flow(function* (itemIds: number[], newStatus: boolean): FlowType {
      const { messageStore } = getParent(self) as RootStore
      self.fetching = true
      const response: Promise<boolean> = yield api.userGroups.updateActivity(itemIds, newStatus)
      self.fetching = false
      if (!response) messageStore.setMessage("message.error.common", "error")
      return response
    }),
  }))
  .postProcessSnapshot(omit(["fetching"]))
/**
  * Un-comment the following to omit model attributes from your snapshots (and from async storage).
  * Useful for sensitive data like passwords, or transitive state like whether a modal is open.

  * Note that you'll need to import `omit` from ramda, which is already included in the project!
  */

type UserGroupStoreType = Instance<typeof UserGroupStoreModel>
export interface UserGroupStore extends UserGroupStoreType {}
type UserGroupStoreSnapshotType = SnapshotOut<typeof UserGroupStoreModel>
export interface UserGroupStoreSnapshot extends UserGroupStoreSnapshotType {}
