import React, { useState, useEffect, KeyboardEvent, useCallback } from "react"
import { observer } from "mobx-react"
import { Screen, Table, TableProperty, FilterButtons, DeactivateButtons } from "../components"
import { makeStyles } from "@material-ui/styles"
import { useStores } from "../models/root-store"
import { useHistory } from "react-router-dom"
import { SearchBar } from "../components/Layout"
import { CommonAttributes } from "eteva-types"
import { RootPaths, SubPaths } from "../routers/Paths"
import { DayProgramModelType } from "../models/day-program-store"

const useStyles = makeStyles({
  container: {
    padding: "20px 50px",
    display: "flex",
    flexDirection: "column",
    maxWidth: 1400,
    width: "100%",
    flex: 1,
    msFlexDirection: "column",
  },
  filterContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    margin: "26px 0",
  },
})

const tableProperties: TableProperty<DayProgramModelType>[] = [
  { key: "title", title: "dayPrograms.title" },
  { key: "active", title: "dayPrograms.active" },
]

const filterButtons = [
  { value: true, label: "dayPrograms.activeFilter" },
  { value: false, label: "dayPrograms.inactiveFilter" },
]

export const DayProgramsScreen: React.FC = observer(() => {
  const {
    dayProgramStore: { dayPrograms, getAll, total, fetching, deleteActivities, changeItemStatuses },
  } = useStores()
  const { container, filterContainer } = useStyles()
  const history = useHistory()
  const [currentPage, setCurrentPage] = useState(0)
  const [selectedRadio, setSelectedRadio] = useState<boolean | undefined>()
  const [selectedItems, setSelectedItems] = useState<number[]>([])
  const [search, setSearch] = useState("")
  const pageSize = 10

  const getAllDayPrograms = async (page = 0) => {
    if (!page) setCurrentPage(0)
    setSelectedRadio(undefined)
    setSelectedItems([])
    await getAll(page, pageSize, search, selectedRadio)
  }

  const memoizedGetDayPrograms = useCallback(getAllDayPrograms, [])

  useEffect(() => {
    memoizedGetDayPrograms()
  }, [memoizedGetDayPrograms])

  const handleEditClick = (id: number) => {
    history.push(`${RootPaths.dayPrograms}${SubPaths.edit}/${id}`)
  }

  const handleSearch = (e: KeyboardEvent<HTMLInputElement>) => {
    const searchQuery = (e.target as HTMLInputElement).value || ""
    setSearch(searchQuery)
    setCurrentPage(0)
    setSelectedItems([])
    getAll(0, pageSize, searchQuery, selectedRadio)
  }

  const handleSelectOne = (id: number) => {
    const alreadySelected = selectedItems.indexOf(id) >= 0
    if (alreadySelected) return setSelectedItems(selectedItems.filter(it => it !== id))
    setSelectedItems([...selectedItems, id])
  }

  const handleSelectAll = () => {
    if (selectedItems.length === dayPrograms.length) return setSelectedItems([])
    setSelectedItems([...dayPrograms.map(it => it.id)])
  }

  const handleUpdateActivityClick = async (newStatus: boolean) => {
    const res = await changeItemStatuses(selectedItems, newStatus)
    if (res) await memoizedGetDayPrograms()
  }

  const handleDeleteActivityClick = async () => {
    await deleteActivities(selectedItems)
    setSelectedItems([])
    getAll(currentPage, pageSize, search, undefined)
  }

  const onRadioClick = (value: boolean) => {
    setSelectedRadio(value)
    setCurrentPage(0)
    setSelectedItems([])
    getAll(0, pageSize, search, value)
  }

  const onChangePage = (event: any, page: number) => {
    if (total > pageSize * page || page > -1) {
      setCurrentPage(page)
      getAll(page, pageSize, search, selectedRadio)
    }
  }

  const renderFilters = () => (
    <div className={filterContainer}>
      {selectedItems?.length ? (
        <DeactivateButtons
          path="dayPrograms"
          onActivateClick={() => handleUpdateActivityClick(true)}
          onDeactivateClick={() => handleUpdateActivityClick(false)}
          onDeleteClick={() => handleDeleteActivityClick()}
        />
      ) : (
        <>
          <FilterButtons
            onRadioClick={onRadioClick}
            buttons={filterButtons}
            selected={selectedRadio}
            defaultChecked="super"
          />
          <SearchBar width="100%" onChange={handleSearch} placeholder="dayPrograms.searchByName" />
        </>
      )}
    </div>
  )

  return (
    <Screen headerTitle="screens.dayPrograms" createButton>
      <div className={container}>
        {renderFilters()}
        <Table<DayProgramModelType & CommonAttributes>
          loading={fetching}
          total={total}
          page={currentPage}
          pageSize={pageSize}
          onChangePage={onChangePage}
          onEditClick={handleEditClick}
          items={dayPrograms}
          properties={tableProperties}
          edit
          selectedItems={selectedItems}
          onSelectOne={handleSelectOne}
          onSelectAll={handleSelectAll}
        />
      </div>
    </Screen>
  )
})
