import { delay } from "../../utils/delay"
import { Instance, types, flow } from "mobx-state-tree"

export const MessageTypes = ["success" as const, "error" as const, "info" as const, "warning" as const]

export type MessageType = typeof MessageTypes[number]

/**
 * For all your snackbar message displaying needs
 */
export const MessageStore = types
  .model("MessageStore")
  .props({
    message: types.maybe(types.string),
    type: types.maybe(types.enumeration(MessageTypes)),
  })
  .actions(self => {
    return {
      clear() {
        self.message = undefined
        self.type = undefined
      },
    }
  })
  .actions(self => {
    return {
      setMessage: flow(function* (messageKey: string, type: MessageType = "info", time = 3000) {
        self.message = messageKey
        self.type = type
        yield delay(time)
        self.clear()
      }),
    }
  })

export interface MessageStore extends Instance<typeof MessageStore> {}
