import React, { ChangeEvent, FC } from "react"
import { EditorState } from "draft-js"
import { useTranslation } from "react-i18next"
import { Editor } from "../Editor"
import { ImageUploadInput } from "../ImageUploadInput"
import { Input } from "../Input"
import { useSectionStyles } from "./styles"
import { ImageWithPreview } from "../../utils/interfaces"
import  MediaFileUploadInput from "../ImageUploadInput/MediaFileUploadInput"

type Props = {
  selectedType: any
  content: EditorState | string | undefined
  onChangeContent: (val: any) => void
  onChangeImage: (files: ImageWithPreview) => void
  onChangeGif: (files: any) => void
  onRemoveImage: () => void
  onRemoveAudio: () => void
  onRemoveGif: () => void
  onChangeAudio: (files: any) => void
  linkText?: string
  onChangeLinkText: (val: string) => void
  imagePreviewUrl?: string | number
}

export const ContentEditor: FC<Props> = ({
  selectedType,
  content,
  onChangeContent,
  onChangeImage,
  onChangeAudio,
  onChangeGif,
  onRemoveGif,
  onRemoveImage,
  onRemoveAudio,
  linkText,
  onChangeLinkText,
  imagePreviewUrl,
}) => {
  const classes = useSectionStyles()
  const { t } = useTranslation()

  const handleChangeLinkText = (e: ChangeEvent<HTMLInputElement>) => {
    onChangeLinkText(e.target.value)
  }

  const handleImageChange = (file: Blob | null) => {
    if (file) {
      const reader = new FileReader()
      reader.onloadend = () => {
        if (reader.result) {
          const obj = { image: file, imagePreviewUrl: reader.result as string }
          onChangeImage(obj)
        }
      }
      reader.readAsDataURL(file)
    }
  }
  const handleAudioChange = (file: Blob | null) => {
    if (file) {
      const reader = new FileReader()
      reader.onloadend = () => {
        if (reader.result) {
          const obj = { audio: file }
          onChangeAudio(obj)
        }
      }
      reader.readAsDataURL(file)
    }
  }
  const handleGifChange = (file: Blob | null) => {
    if (file) {
      const reader = new FileReader()
      reader.onloadend = () => {
        if (reader.result) {
          const obj = { gif: file }
          onChangeGif(obj)
        }
      }
      reader.readAsDataURL(file)
    }
  }
  const renderContentEditor = () => {
    switch (selectedType) {
      case "wysiwyg":
        return <Editor state={content as EditorState} onChange={onChangeContent} />
      case "link":
        return (
          <>
            <label className={classes.altTextLabel} htmlFor="link-text">
              {t("form.link")}
            </label>
            <Input id="link" value={content || ""} onChange={onChangeContent} />
            <label className={classes.altTextLabel} htmlFor="link-text">
              {t("form.linkText")}
            </label>
            <Input id="link-text" value={linkText || ""} onChange={handleChangeLinkText} />
          </>
        )
      case "youtube":
        return <Input value={content || ""} onChange={onChangeContent} multiline />
      case "image":
        return (
          <>
            <ImageUploadInput
              id="section-image"
              previewUrl={imagePreviewUrl}
              onChange={handleImageChange}
              onDelete={onRemoveImage}
              contentEditorImage={true}
            />
            <label className={classes.altTextLabel} htmlFor="section-image-alt-text">
              {t("form.altText")}
            </label>
            <Input
              className={classes.altTextInput}
              id="section-image-alt-text"
              value={content || ""}
              onChange={onChangeContent}
            />
          </>
        )
      case "audio":
        return (
          <>
            <MediaFileUploadInput type={'audio'} id="section-audio" onChange={handleAudioChange} onDelete={onRemoveAudio} />
            <label className={classes.altTextLabel} htmlFor="section-audio-alt-text">
              {t("form.altAudio")}
            </label>
            <Input value={content} onChange={onChangeContent} className={classes.altTextInput} />
          </>
        )
      case "gif":
        return (
          <>
            <MediaFileUploadInput type={'gif'} id="section-audio" onChange={handleGifChange} onDelete={onRemoveGif} />
            <label className={classes.altTextLabel} htmlFor="section-gif-alt-text">
              {t("form.altGif")}
            </label>
            <Input value={content} onChange={onChangeContent} className={classes.altTextInput} />
          </>
        )
      case "dreambroker":
        return <Input value={content || ""} onChange={onChangeContent} multiline />
      default:
        return null
    }
  }

  return renderContentEditor()
}
