import React, { FC } from "react"
import { Link } from "react-router-dom"
import { withStyles, WithStyles, createStyles } from "@material-ui/core"
import { color } from "../theme"

const styles = () =>
  createStyles({
    link: {
      color: color.palette.white,
      fontWeight: 700,
      display: "flex",
      flex: 1,
      flexDirection: "row",
      alignItems: "center",
      textDecoration: "none",
      "&:hover": {
        textDecoration: "none",
      },
    },
  })

export interface ListLinkProps {
  to: string
}

type Props = WithStyles<typeof styles> & ListLinkProps

const ListLink: FC<Props> = props => {
  return (
    <Link className={props.classes.link} to={props.to}>
      {props.children}
    </Link>
  )
}
export default withStyles(styles)(ListLink)
