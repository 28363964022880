const OPTIONS = ["inline", "blockType", "fontSize", "list", "emoji", "history"]

export const toolbarSettings = (buttonClassName: string) => {
  return {
    options: OPTIONS,
    inline: {
      options: ["bold", "italic", "underline"],
      bold: {
        className: buttonClassName,
      },
      italic: {
        className: buttonClassName,
      },
      underline: {
        className: buttonClassName,
      },
    },
    list: {
      options: ["ordered", "unordered"],
      ordered: {
        className: buttonClassName,
      },
      unordered: {
        className: buttonClassName,
      },
    },
    blockType: {
      className: buttonClassName,
    },
    fontSize: {
      className: buttonClassName,
    },
    emoji: {
      className: buttonClassName,
    },
    remove: {
      className: buttonClassName,
    },
    history: {
      undo: {
        className: buttonClassName,
      },
      redo: {
        className: buttonClassName,
      },
    },
  }
}
