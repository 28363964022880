import React, { FC } from "react"
import { makeStyles } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { Button } from "./Button"

interface TabButton {
  label: string
  value: any
}

export interface TabsProps {
  onRadioClick: (value: any) => void
  buttons: Array<TabButton>
  defaultChecked: any
  selected: any
}

const useStyles = makeStyles({
  tabContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  button: {
    marginRight: 16,
  },
  text: {
    fontFamily: "Bw Surco",
    fontWeight: 600,
    fontSize: 16,
  },
})

export const FilterButtons: FC<TabsProps> = props => {
  const { onRadioClick, buttons, defaultChecked, selected } = props
  const classes = useStyles()
  const { t } = useTranslation()

  const renderTabButton = ({ label, value }: TabButton) => {
    const selectButton = () => onRadioClick(selected === value ? undefined : value)
    return (
      <Button
        key={value}
        value={value}
        defaultChecked={defaultChecked === value}
        onClick={selectButton}
        tight
        primary={selected === value}
        className={classes.button}
      >
        <span className={classes.text}>{t(label)}</span>
      </Button>
    )
  }

  const renderTabButtons = () => {
    return buttons.map(button => renderTabButton(button))
  }

  return <div className={classes.tabContainer}>{renderTabButtons()}</div>
}
