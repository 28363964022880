import { makeStyles } from "@material-ui/styles"
import { color } from "../../theme"

const WRAPPER_MARGIN = 0

export const useEditorStyles = makeStyles({
  toolbar: {
    border: "none",
    backgroundColor: color.palette.white,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    marginBottom: 0,
  },
  wrapper: {
    marginTop: WRAPPER_MARGIN,
    marginBottom: WRAPPER_MARGIN,
    border: `1px solid ${color.line}`,
    borderRadius: 3,
    width: "100%",
  },
  editor: {
    minHeight: 250,
    padding: "0 20px",
    backgroundColor: color.palette.offWhite,
    cursor: "text",
    transition: "all 150ms",
    "&::placeholder": {
      color: color.dim,
    },
    "&:hover": {
      backgroundColor: color.transparent,
    },
    "&:focus-within": {
      backgroundColor: color.transparent,
    },
  },
  button: {
    backgroundColor: color.transparent,
    border: "none",
    borderRadius: 3,
    transition: "all 250ms",
    "&:hover": {
      backgroundColor: color.palette.offWhite,
    },
  },
})
