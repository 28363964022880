import { RootStore } from "./../root-store/root-store"
import { Instance, SnapshotOut, types as t, flow, getParent, cast } from "mobx-state-tree"
import { FlowType } from "eteva-rest-api"
import { IContent, PageResponse } from "eteva-types"
import { api } from "./../../services/api"
import { omit } from "ramda"
import { withEnvironment } from "../extensions"
import { ContentSectionWithPreview } from "../../utils/interfaces"
import { stripImageData } from "../../utils/storeUtils"

const ContentModel = t.model("ContentModel", {
  id: t.identifierNumber,
  title: t.string,
  imageId: t.maybeNull(t.number),
  active: t.boolean,
  likes: t.maybe(t.number),
  dislikes: t.maybe(t.number),
  hits: t.maybe(t.number)
})

export type ContentModelType = Instance<typeof ContentModel>

const ContentSectionModel = t.model("ContentSectionModel", {
  id: t.maybe(t.identifierNumber),
  type: t.string,
  imageId: t.maybeNull(t.number),
  audioId: t.maybeNull(t.number),
  gifId: t.maybeNull(t.number),
  content: t.maybe(t.frozen()),
  position: t.number,
})

export type ContentSectionModelType = Instance<typeof ContentSectionModel>

export const ContentStoreModel = t
  .model("ContentStore")
  .props({
    contents: t.optional(t.array(ContentModel), []),
    total: t.optional(t.number, 0),
    fetching: t.optional(t.boolean, false),
    sections: t.optional(t.array(ContentSectionModel), []),
  })
  .extend(withEnvironment)
  .actions(self => ({
    getAll: flow(function* (page: number, pageSize: number, search?: string, active?: boolean): FlowType {
      const { messageStore } = getParent(self) as RootStore
      self.fetching = true
      const response: PageResponse<IContent> = yield api.contents.getAll({ page, pageSize, search }, active)
      self.fetching = false
      if (response) {
        self.contents = cast(response.results)
        self.total = response.total
        return true
      }
      messageStore.setMessage("message.error.common", "error")
      return false
    }),
    getOne: flow(function* (contentId): FlowType {
      const { messageStore } = getParent(self) as RootStore
      self.fetching = true
      const result: IContent = yield api.contents.getOne(contentId)
      self.fetching = false
      if (result) {
        self.sections = cast(result.sections)
        return result
      }
      messageStore.setMessage("message.error.common", "error")
      return null
    }),
    clearCurrentSections: () => {
      self.sections.clear()
    },
  }))
  .actions(self => ({
    create: flow(function* (content: FormData): FlowType {
      const { messageStore } = getParent(self) as RootStore
      self.fetching = true
      const result: IContent = yield api.contents.createContent(content)
      self.fetching = false
      if (result) {
        messageStore.setMessage("message.success.content.added", "success")
        return result.id
      }
      messageStore.setMessage("message.error.common", "error")
      return false
    }),
    update: flow(function* (content: FormData): FlowType {
      const { messageStore } = getParent(self) as RootStore
      self.fetching = true
      const result: boolean = yield api.contents.update(content)
      self.fetching = false
      if (result) {
        messageStore.setMessage("message.success.content.updated", "success")
        return true
      }
      messageStore.setMessage("message.error.common", "error")
      return false
    }),
    delete: flow(function* (contentId): FlowType {
      const { messageStore } = getParent(self) as RootStore
      self.fetching = true
      const result = yield api.contents.delete(contentId)
      self.fetching = false
      if (result) {
        messageStore.setMessage("message.success.content.deleted", "success")
        return true
      }
      messageStore.setMessage("message.error.common", "error")
      return null
    }),
    deleteActivities: flow(function* (contentIds: number[]): FlowType {
      const { messageStore } = getParent(self) as RootStore
      self.fetching = true
      const result = yield api.contents.deleteActivities(contentIds)
      self.fetching = false
      if (result) {
        messageStore.setMessage("message.success.content.deleted", "success")
        return true
      }
      messageStore.setMessage("message.error.common", "error")
      return null
    }),
    submitSections: flow(function* (sections: ContentSectionWithPreview[], contentId: number): FlowType {
      const { messageStore }: RootStore = getParent(self)

      const prevSections = [...self.sections]
      const prevSectionIds = prevSections.map(it => it.id)
      const currentSections = sections.filter(it => prevSectionIds.includes(it.id))
      const currentSectionIds = sections.map(it => it.id)
      const newSections = sections.filter(it => !prevSectionIds.includes(it.id)).map(it => ({ ...it, contentId }))
      const removedSections = prevSections.filter(it => !currentSectionIds.includes(it.id))

      self.fetching = true
      let operations = 0
      let successes = 0

      if (currentSections && currentSectionIds.length) {
        operations += currentSections.length;
        yield Promise.all(
          currentSections.map(async (it) => {
            const submitForm = new FormData();
            const formData = { ...stripImageData(it) };
            submitForm.append("data", JSON.stringify(formData));
      
            if (it.type === "image" && it.imageFile) {
              submitForm.append("image", it.imageFile);
            }
            if (it.type === 'gif' && it.gifFile) {
              submitForm.append("gif", it.gifFile);
            }
            if (it.type === "audio" && it.audioFile) {
              submitForm.append("audio", it.audioFile);
            }
      
            const res = await api.contentSections.update(submitForm);
            
            if (res) {
              successes += 1;
              return true;
            }
      
            messageStore.setMessage("message.error.section.update", "error");
            return false;
          })
        );
      }
      
      if (newSections.length) {
        operations += newSections.length
        yield Promise.all(
          newSections.map(async it => {
            const submitForm = new FormData()
            const formData = { ...stripImageData(it) }
            submitForm.append("data", JSON.stringify(formData))
            if (it.type === "image" && it.imageFile) submitForm.append("image", it.imageFile)
            if (it.type === "audio" && it.audioFile) submitForm.append("audio", it.audioFile)
            if (it.type === "gif" && it.gifFile) submitForm.append("gif", it.gifFile)
            const res = await api.contentSections.create(submitForm)
            if (res) {
              successes += 1
              return true
            }
            messageStore.setMessage("message.error.section.save", "error")
            return false
          })
        )
      }

      if (removedSections.length) {
        operations += removedSections.length
        yield Promise.all(
          removedSections.map(async it => {
            if (it.id) {
              const res = await api.contentSections.delete(it.id)
              if (res) {
                successes += 1
                return true
              }
              messageStore.setMessage("message.error.section.delete", "error")
            }
            return false
          })
        )
      }

      self.fetching = false
      yield self.getOne(contentId)
      if (operations === successes) {
        self.clearCurrentSections()
      }
      return operations === successes
    }),
    changeItemStatuses: flow(function* (itemIds: number[], newStatus: boolean): FlowType {
      const { messageStore } = getParent(self) as RootStore
      self.fetching = true
      const response: Promise<boolean> = yield api.contents.updateActivity(itemIds, newStatus)
      self.fetching = false
      if (!response) messageStore.setMessage("message.error.common", "error")
      return response
    }),
  }))
  .postProcessSnapshot(omit(["fetching"]))
/**
  * Un-comment the following to omit model attributes from your snapshots (and from async storage).
  * Useful for sensitive data like passwords, or transitive state like whether a modal is open.

  * Note that you'll need to import `omit` from ramda, which is already included in the project!
  */

type ContentStoreType = Instance<typeof ContentStoreModel>
export interface ContentStore extends ContentStoreType {}
type ContentStoreSnapshotType = SnapshotOut<typeof ContentStoreModel>
export interface ContentStoreSnapshot extends ContentStoreSnapshotType {}
