import React, { FC } from "react"
import { observer } from "mobx-react"
import { Snackbar as MUISnackbar, SnackbarContent, IconButton, Theme, colors } from "@material-ui/core"
import { CheckCircle, Warning, Info, Close, Error } from "@material-ui/icons"
import { SnackbarOrigin } from "@material-ui/core/Snackbar"
import { makeStyles } from "@material-ui/styles"
import { useTranslation } from "react-i18next"
import { useStores } from "../../models/root-store"
import { MessageType } from "../../models/message-store/MessageStore"

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  success: {
    backgroundColor: colors.green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: 4,
  },
  message: {
    display: "flex",
    alignItems: "center",
  },
}))

interface CustomSnackbarContentProps {
  message: string | null | undefined
  onClose: () => void
  variant: MessageType
}

const variantIcon = {
  success: CheckCircle,
  warning: Warning,
  error: Error,
  info: Info,
}

const CustomSnackbarContent: FC<CustomSnackbarContentProps> = props => {
  const { message, onClose, variant } = props
  const classes = useStyles()
  const Icon = variantIcon[variant]
  const classVariant = (classes as any)[variant]

  return (
    <SnackbarContent
      className={classVariant}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={`${classes.icon} ${classes.iconVariant}`} />
          {message}
        </span>
      }
      action={[
        <IconButton key="close" aria-label="Close" color="inherit" onClick={onClose}>
          <Close className={classes.icon} />
        </IconButton>,
      ]}
    />
  )
}

interface OwnProps {
  anchorOrigin?: SnackbarOrigin
}

export const Snackbar: FC<OwnProps> = observer(props => {
  const classes = useStyles()
  const { t } = useTranslation()
  const {
    messageStore: { message, type, clear },
  } = useStores()
  const onClose = () => {
    clear()
  }

  const renderContent = () => {
    if (!message || !type) return
    return <CustomSnackbarContent onClose={onClose} variant={type} message={t(message)} />
  }

  return (
    <MUISnackbar
      anchorOrigin={props.anchorOrigin || { vertical: "top", horizontal: "right" }}
      open={!!message}
      className={classes.container}
    >
      {renderContent()}
    </MUISnackbar>
  )
})
