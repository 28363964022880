import * as React from "react"
import { TextPresets, presets, usePresetStyles } from "./Text.presets"
import { useTranslation } from "react-i18next"
import { joinClassnames } from "../../utils/joinClassnames"

export interface TextProps extends React.HTMLProps<React.ReactText> {
  /**
   * Children components.
   */
  children?: React.ReactNode

  /**
   * Text which is looked up via i18n.
   */
  tx?: string

  /**
   * Optional options to pass to i18n. Useful for interpolation
   * as well as explicitly setting locale or translation fallbacks.
   */
  txOptions?: object

  /**
   * The text to display if not using `tx` or nested components.
   */
  text?: string

  /**
   * An optional style override.
   */
  className?: string

  /**
   * One of the different types of text presets.
   */
  preset?: TextPresets

  /**
   * Optional HTML element tag, defaults to span.
   */
  htmlElement?: keyof JSX.IntrinsicElements
}

/**
 * For your text displaying needs.
 *
 * This component is a HOC over the built-in React Native one.
 */
export function Text(props: TextProps & React.ReactNode) {
  const { t } = useTranslation()
  const { htmlElement, preset = "default", tx, txOptions, text, children, className } = props

  const i18nText = tx && t(tx, txOptions)
  const content = i18nText || text || children
  const Tag = htmlElement ? htmlElement : presets[preset].element

  const classNames = joinClassnames(className, usePresetStyles()[preset])

  return <Tag className={classNames}>{content}</Tag>
}
