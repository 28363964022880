import i18next from "i18next"
import { EditorState, convertToRaw, ContentState } from "draft-js"
import draftToHtml from "draftjs-to-html"
import htmlToDraft from "html-to-draftjs"
import { languages } from "../i18n"

/** Get current language */
export const getCurrentLang = (): string => {
  const availableLanguages = languages.map(({ value }) => value)

  if (i18next.language !== undefined) {
    const currentLang = i18next.language.substr(0, 2)
    if (availableLanguages.includes(currentLang)) {
      return currentLang
    }
  }
  return languages[0].value // Fallback to default lang
}

export const editorStateToHtml = (state: EditorState): string => {
  return draftToHtml(convertToRaw(state.getCurrentContent()))
}

export const htmlToEditorState = (html: string): EditorState => {
  const draftDescription = htmlToDraft(html)
  const { contentBlocks, entityMap } = draftDescription
  const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap)
  return EditorState.createWithContent(contentState)
}

/**
 * Check that password is strong enough
 * @param {string} password - User password
 */
export function isStrongPassword(password: string): string | boolean {
  // Password must:
  // - contain at least 1 alphabetical character
  // - have at least 8 characters
  const pattern = /^(?=.*[a-z])(?=.{8,})/
  return password && pattern.test(password)
}

/**
 * A simple email validator to the form of something@something.something
 * @param email The email string to be validated
 */
export const isEmail = (email: string): boolean => {
  const re = /\S+@\S+\.\S+/
  return re.test(String(email).toLowerCase())
}

export const formatFormData = (obj: any) => {
  const newObj = { ...obj }
  delete newObj.imageFile
  delete newObj.imagePreviewUrl
  delete newObj.createdAt
  delete newObj.updatedAt
  return newObj
}
