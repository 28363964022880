import React, { useState, useEffect, useCallback } from "react"
import { useHistory, useParams } from "react-router-dom"
import { observer } from "mobx-react"
import { CommonAttributes } from "eteva-types"
import { makeStyles } from "@material-ui/core"
import { Screen, Loader, Form } from "../../components"
import { useStores } from "../../models/root-store"
import { FormSection, FormSelectField } from "../../components/Form/FormTypes"
import { UserGroupWithPreview } from "../../utils/interfaces"
import { formatFormData } from "../../utils"
// import { ModificationDetails } from "../../components/ModificationDetails"
import { RootPaths } from "../../routers/Paths"

const useStyles = makeStyles({
  container: {
    padding: 10,
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    "@media (min-width:600px)": {
      padding: 50,
      display: "flex",
      flex: 1,
    },
  },
})

interface UserGroupScreenProps {}

export const UserGroupScreen: React.FC<UserGroupScreenProps> = observer(() => {
  const {
    userGroupStore: { fetching, getOne, create, update },
    formStore: { setSectionDirty },
  } = useStores()
  const [userGroupState, setUserGroupState] = useState<Partial<UserGroupWithPreview & CommonAttributes>>({})
  const { container } = useStyles()
  const history = useHistory()
  const { id } = useParams<{ id: string }>()

  const getUserGroupWithId = async (id: number) => {
    const newUserGroup = await getOne(id)
    if (newUserGroup) {
      setUserGroupState(newUserGroup)
    }
  }

  const memoizedGetUserGroup = useCallback(getUserGroupWithId, [])

  useEffect(() => {
    if (id && !isNaN(Number(id))) {
      memoizedGetUserGroup(Number(id))
    }
  }, [id, memoizedGetUserGroup])

  const handleSubmit = async (state: UserGroupWithPreview) => {
    setSectionDirty()

    const submitForm = new FormData()
    const formData = { ...formatFormData(state) }
    submitForm.append("data", JSON.stringify(formData))
    if (state.imageFile) submitForm.append("image", state.imageFile)

    let success = false
    if (state.id) {
      success = await update(submitForm)
    } else {
      success = await create(submitForm)
    }

    if (success) history.push(RootPaths.userGroups)
  }

  const handleCancel = () => history.push(RootPaths.userGroups)

  const header = id ? "userGroups.edit" : "userGroups.add"

  const getFormFields = (type: string, title: string) =>
    ({
      type,
      title,
      fields: [
        {
          id: "title",
          type: "input",
          label: "form.title",
          required: true,
        },
        {
          id: "language",
          type: "select",
          options: [{value: 'fi', label: 'form.fi'}, {value: 'sv', label: 'form.sv'}],
          label: "form.language",
          required: true,
        } as FormSelectField,
        {
          id: "imageFile",
          type: "image",
          label: "form.image",
          required: true,
        },
        {
          id: "description",
          type: "wysiwyg",
          label: "form.description",
          required: true,
        },
        {
          id: "help",
          type: "help",
          label: "form.help",
        },
        {
          id: "submit",
          type: "submit",
          label: "submit",
          addLabel: "form.save",
          updateLabel: "form.update",
        },
      ],
    } as FormSection)

  const renderContent = () => {
    if (fetching) return <Loader />
    return (
      <div className={container}>
        <Form
          content={userGroupState}
          formId="user_groups"
          section={getFormFields("user_groups", "")}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
        />

      </div>
    )
  }
  return <Screen headerTitle={header}>{renderContent()}</Screen>
})
