import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { makeStyles } from "@material-ui/styles"
import { color } from "../theme"
import { Input, Button, ModalDialog } from "../components"
import { FormControlLabel, Checkbox } from "@material-ui/core"
import { useStores } from "../models/root-store"
import { Snackbar } from "../components/Layout/Snackbar"
import { observer } from "mobx-react"

import logo from "../static/eteva-white.png"

const useStyles = makeStyles({
  root: {
    display: "flex",
    minHeight: "100vh",
    backgroundColor: "white",
  },
  loginContainer: {
    flex: 0.5,
    padding: "60px 80px",
    position: "relative",
  },
  logoHeader: {
    fontSize: 14,
    textTransform: "uppercase",
    fontWeight: "bold",
  },
  header: {
    fontSize: 64,
    fontWeight: "bold",
    textTransform: "uppercase",
    color: "black",
  },
  secondary: {
    fontSize: 18,
    color: color.dim,
    maxWidth: 700,
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    maxWidth: 700,
  },
  label: {
    fontWeight: 500,
    fontSize: 24,
    textIndent: 15,
    marginTop: 40,
    color: color.text,
  },
  input: {
    height: 70,
    fontSize: 20,
  },
  formBottom: {
    marginTop: 32,
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    width: "100%",
  },
  checkbox: {
    marginRight: 10,
  },
  checkboxChecked: {
    marginRight: 10,
    color: "blue",
  },
  checkboxLabel: {
    fontSize: 20,
    color: color.text,
  },
  linkContainer: {
    marginTop: 20,
    fontSize: "18px",
    backgroundColor: "transparent",
    borderColor: "transparent",
  },
  link: {
    color: color.secondary,
    textDecoration: "underline",
  },
  button: {
    fontSize: 22,
    padding: "14px 32px",
    fontWeight: 600,
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  newsContainer: {
    position: "relative",
    display: "flex",
    flex: 0.5,
    flexFlow: "column nowrap",
    justifyContent: "center",
    backgroundPosition: "bottom",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundColor: color.primary,
  },
  logoContainer: {
    zIndex: 3,
    display: "flex",
    flex: 0.8,
    flexFlow: "column nowrap",
    justifyContent: "center",
    alignItems: "center",
  },
  logo: {
    width: "30%",
  },
  horizontal: {
    flexDirection: "row",
  },
  resetContainer: {
    marginBottom: 16,
  },
  resetInput: {
    height: 50,
    fontSize: 16,
    fontFamily: "Bw Surco",
    backgroundColor: color.palette.offWhite,
  },
})

export const LoginScreen = observer(() => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { userStore } = useStores()
  const [email, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [resetEmail, setResetEmail] = useState("")
  const [rememberMe, setRememberMe] = useState(true)
  const [openModal, setOpenModal] = useState(false)

  const openAcceptModal = () => setOpenModal(true)
  const handleClose = () => setOpenModal(false)

  const sendResetPasswordLink = async () => {
    if (resetEmail.length) {
      await userStore.requestResetPasswordEmail(resetEmail)
    }
    handleClose()
  }
  const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => setUsername(e.target.value)
  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)
  const handleResetEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => setResetEmail(e.target.value)

  const handleLogin = () => {
    if (email.length && password.length) {
      userStore.login(email, password)
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault()
      e.stopPropagation()
      handleLogin()
    }
  }

  const checkboxClicked = (_: any, value: boolean) => {
    setRememberMe(value)
  }

  const formBottom = () => (
    <div className={classes.formBottom}>
      <div className={classes.horizontal}>
        <FormControlLabel
          classes={{ label: classes.checkboxLabel }}
          control={
            <Checkbox checked={rememberMe} onChange={checkboxClicked} className={classes.checkbox} color="primary" />
          }
          label={t("login.rememberMe")}
        />
      </div>
      <div className={classes.buttonContainer}>
        <Button
          disabled={!email || !password}
          className={classes.button}
          onClick={handleLogin}
          loading={userStore.fetching}
          primary
          label={t("login.signIn")}
        />
        <button onClick={openAcceptModal} className={classes.linkContainer}>
          <span className={classes.link}>{t("login.forgotPassword")}</span>
        </button>
      </div>
      <ModalDialog
        isOpen={openModal}
        title="login.modalTitle"
        text="login.modalText"
        onLeftClick={sendResetPasswordLink}
        onRightClick={handleClose}
        leftButtonText="common.accept"
        rightButtonText="common.cancel"
      >
        <div className={classes.resetContainer}>
          <p>{t("login.modalText")}</p>
          <Input
            className={classes.resetInput}
            type="email"
            placeholder={t("login.email")}
            value={resetEmail}
            onChange={handleResetEmailChange}
          />
        </div>
      </ModalDialog>
    </div>
  )

  const renderForm = () => (
    <div className={classes.formContainer}>
      <p className={classes.label}>{t("login.email")}</p>
      <Input
        className={classes.input}
        placeholder={t("login.email")}
        value={email}
        onChange={handleUsernameChange}
        onKeyDown={handleKeyDown}
      />
      <p className={classes.label}>{t("login.password")}</p>
      <Input
        className={classes.input}
        type="password"
        placeholder={t("login.password")}
        value={password}
        onChange={handlePasswordChange}
        onKeyDown={handleKeyDown}
      />
      {formBottom()}
    </div>
  )

  return (
    <div className={classes.root}>
      <div className={classes.loginContainer}>
        <h1 className={classes.header}>{t("login.signIn")}</h1>
        <p className={classes.secondary}>{t("login.wellcomeText")}</p>
        {renderForm()}
        <Snackbar />
      </div>
      <div className={classes.newsContainer}>
        <div className={classes.logoContainer}>
          <img src={logo} alt="logo" className={classes.logo} />
        </div>
      </div>
    </div>
  )
})
