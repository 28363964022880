import { Instance, SnapshotOut, types } from "mobx-state-tree"
import { AppInfoStoreModel } from "../app-info-store"
import { CategoryStoreModel } from "../category-store"
import { SubCategoryStoreModel } from "../subcategory-store"
import { ContentStoreModel } from "../content-store"
import { DayProgramStoreModel } from "../day-program-store"
import { FormStore } from "../form-store/FormStore"
import { MessageStore } from "../message-store/MessageStore"
import { UserGroupStoreModel } from "../user-group-store/UserGroupStore"
import { UserStoreModel } from "../user-store/UserStore"
/**
 * A RootStore model.
 */
export const RootStoreModel = types.model("RootStore").props({
  messageStore: types.optional(MessageStore, {}),
  formStore: types.optional(FormStore, {}),
  userStore: types.optional(UserStoreModel, { state: "LOGGED_OUT" }),
  userGroupStore: types.optional(UserGroupStoreModel, {}),
  categoryStore: types.optional(CategoryStoreModel, {}),
  subCategoryStore: types.optional(SubCategoryStoreModel, {}),
  contentStore: types.optional(ContentStoreModel, {}),
  dayProgramStore: types.optional(DayProgramStoreModel, {}),
  appInfoStore: types.optional(AppInfoStoreModel, {}),
})

/**
 * The RootStore instance.
 */
export type RootStore = Instance<typeof RootStoreModel>

/**
 * The data of a RootStore.
 */
export type RootStoreSnapshot = SnapshotOut<typeof RootStoreModel>
