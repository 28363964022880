import { palette } from "./palette"

/**
 * Roles for colors.  Prefer using these over the palette.  It makes it easier
 * to change things.
 *
 * The only roles we need to place in here are the ones that span through the app.
 *
 * If you have a specific use-case, like a spinner color.  It makes more sense to
 * put that in the <Spinner /> component.
 */
export const color = {
  /**
   * The palette is available to use, but prefer using the name.
   */
  palette,
  /**
   * A helper for making something see-thru. Use sparingly as many layers of transparency
   * can cause older Android devices to slow down due to the excessive compositing required
   * by their under-powered GPUs.
   */
  transparent: "rgba(0, 0, 0, 0)",
  /**
   * The screen background.
   */
  background: palette.white,

  backgroundDarker: palette.offWhite,
  /**
   * The main tinting color.
   */
  primary: palette.orange,
  /**
   * The main tinting color, but darker.
   */
  primaryDarker: palette.orangeDarker,
  /**
   * A subtle color used for borders and lines.
   */
  line: palette.lighterGrey,
  /**
   * A subtler color used for borders and lines.
   */
  lineLighter: palette.lightestGrey,
  /**
   * The default color of text in many components.
   */
  text: palette.graphite,
  /**
   * Secondary information.
   */
  dim: palette.lightGrey,
  /**
   * Error messages and icons.
   */
  error: palette.angry,

  /**
   * Storybook background for Text stories, or any stories where
   * the text color is color.text, which is white by default, and does not show
   * in Stories against the default white background
   */
  storybookDarkBg: palette.black,

  /**
   * Storybook text color for stories that display Text components against the
   * white background
   */
  storybookTextColor: palette.black,

  bgGradientStart: palette.veryPaleOrange,

  bgGradientEnd: palette.lightGrayishRed,

  mainGradientStart: palette.pureOrange,

  mainGradientEnd: palette.pureOrangeDarker,

  /**
   * Secondary element color - for secondary button texts or backgrounds.
   */
  secondary: palette.darkNavy,

  /**
   * Tertiary element color - for ticks and whatnot.
   */
  tertiary: palette.lime,

  lightOpaque: palette.transparentWhite,

  lighterOpaque: palette.moreTransparentWhite,

  /**
   * For shadow-like box shadows and such.
   */
  shadow: palette.veryTransparentBlack,
}
