import React, { useState, useEffect, KeyboardEvent, useCallback } from "react"
import { observer } from "mobx-react"
import { Screen, Table, TableProperty, FilterButtons, DeactivateButtons } from "../components"
import { makeStyles } from "@material-ui/styles"
import { useStores } from "../models/root-store"
import { useHistory } from "react-router-dom"
import { SearchBar } from "../components/Layout"
import { RoleType, CommonAttributes, Roles } from "eteva-types"
import { RootPaths, SubPaths } from "../routers/Paths"
import { UserModelType } from "../models/user-store"

const useStyles = makeStyles({
  container: {
    padding: "20px 50px",
    display: "flex",
    flexDirection: "column",
    maxWidth: 1400,
    width: "100%",
    flex: 1,
    msFlexDirection: "column",
  },
  filterContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    margin: "26px 0",
  },
})

const tableProperties: TableProperty<UserModelType>[] = [
  { key: "email", title: "users.email" },
  { key: "role", title: "users.role" },
]

const filterButtons = [
  { value: Roles.SUPERADMIN, label: "users.roles.superAdmin" },
  { value: Roles.ADMIN, label: "users.roles.admin" },
  { value: Roles.EDITOR, label: "users.roles.editor" },
]

export const UsersScreen: React.FC = observer(() => {
  const {
    userStore: { users, usersTotal, fetching, getUsers, currentUser, deleteBatch },
  } = useStores()
  const classes = useStyles()
  const history = useHistory()
  const [selectedRadio, setSelectedRadio] = useState("")
  const [currentPage, setCurrentPage] = useState(0)
  const [search, setSearch] = useState("")
  const [selectedItems, setSelectedItems] = useState<number[]>([])
  const pageSize = 10

  if (!currentUser || (currentUser.role !== Roles.SUPERADMIN && currentUser.role !== Roles.ADMIN)) {
    // history.goBack()
  }
  const getAllUsers = async (page = 0) => {
    if (!page) setCurrentPage(0)
    setSelectedItems([])
    setSelectedRadio("")
    await getUsers(page, pageSize, search)
  }

  const memoizedGetUsers = useCallback(getAllUsers, [])

  useEffect(() => {
    memoizedGetUsers()
  }, [memoizedGetUsers])

  const handleSearch = (e: KeyboardEvent<HTMLInputElement>) => {
    const searchQuery = (e.target as HTMLInputElement).value || ""
    setSearch(searchQuery)
    setCurrentPage(0)
    getUsers(currentPage, pageSize, searchQuery, (selectedRadio as RoleType) || undefined)
  }

  const handleSelectOne = (id: number) => {
    if (selectedItems.indexOf(id) >= 0) return setSelectedItems(selectedItems.filter(it => it !== id))
    setSelectedItems([...selectedItems, id])
  }

  const handleSelectAll = () => {
    if (selectedItems.length === users.length) return setSelectedItems([])
    setSelectedItems([...users.map(it => it.id)])
  }

  const handleDeleteClick = async () => {
    const res = await deleteBatch(selectedItems)
    if (res) await memoizedGetUsers()
  }

  const toSingle = (id: number) => {
    history.push(`${RootPaths.users}${SubPaths.edit}/${id}`)
  }

  const onRadioClick = (value: string) => {
    setSelectedRadio(value)
    setCurrentPage(0)
    setSelectedItems([])
    getUsers(0, pageSize, search, (value as RoleType) || undefined)
  }

  const onChangePage = (event: any, page: number) => {
    if (usersTotal > pageSize * page || page > -1) {
      setCurrentPage(page)
      getUsers(page, pageSize, search, (selectedRadio as RoleType) || undefined)
    }
  }

  const renderFilters = () => (
    <div className={classes.filterContainer}>
      {selectedItems?.length ? (
        <DeactivateButtons path="users" onDeleteClick={handleDeleteClick} />
      ) : (
        <>
          <FilterButtons
            onRadioClick={onRadioClick}
            buttons={filterButtons}
            selected={selectedRadio}
            defaultChecked="super"
          />
          <SearchBar width="100%" onChange={handleSearch} placeholder="users.searchByEmail" />
        </>
      )}
    </div>
  )

  return (
    <Screen headerTitle="screens.users" createButton>
      <div className={classes.container}>
        {renderFilters()}
        <Table<UserModelType & CommonAttributes>
          loading={fetching}
          total={usersTotal}
          page={currentPage}
          pageSize={pageSize}
          onChangePage={onChangePage}
          onEditClick={toSingle}
          items={users}
          properties={tableProperties}
          edit
          onSelectOne={handleSelectOne}
          onSelectAll={handleSelectAll}
          selectedItems={selectedItems}
        />
      </div>
    </Screen>
  )
})
