import { createMuiTheme } from "@material-ui/core"
import { PaletteColor } from "@material-ui/core/styles/createPalette"
import { fiFI } from "@material-ui/core/locale"

export const primaryPaletteColor: PaletteColor = {
  main: "#FFB422",
  light: "#ffcd6a",
  dark: "#e79900",
  contrastText: "#000000",
}

export const secondaryPaletteColor: PaletteColor = {
  main: "#114E68",
  light: "#156182",
  dark: "#0e3e53",
  contrastText: "#FFFFFF",
}

export const MuiTheme = createMuiTheme(
  {
    palette: {
      primary: primaryPaletteColor,
      secondary: secondaryPaletteColor,
    },
  },
  fiFI
)
