import React, { FC } from "react"
import { makeStyles, CircularProgress } from "@material-ui/core"
import { color } from "../theme"

const useStyles = makeStyles({
  container: {
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: 150,
    display: "flex",
    flex: 1,
  },
  circularProgress: {
    color: color.primary,
  },
})

export interface LoaderProps {}

export const Loader: FC<LoaderProps> = props => {
  const { container, circularProgress } = useStyles()

  return (
    <div className={container}>
      <CircularProgress size={26} className={circularProgress} color="secondary" />
    </div>
  )
}
