import React, { useEffect } from "react"
import { Switch, Route, Redirect, RouteProps } from "react-router-dom"
import {
  UserScreen,
  UsersScreen,
  UserGroupScreen,
  UserGroupsScreen,
  CategoryScreen,
  CategoriesScreen,
  ContentScreen,
  ContentsScreen,
  DayProgramsScreen,
  DayProgramScreen,
  AnalyticsScreen,
  AppInfoScreen,
  SubCategoriesScreen,
} from "../screens"
import { Layout } from "../components/Layout"
import { RootPaths, SubPaths } from "./Paths"
import { useStores } from "../models/root-store"
import { SubCategoryScreen } from "../screens/SubCategoryScreen"
import { observer } from "mobx-react"

const CreateAndEditRoutes = ({ path, children }: RouteProps) => (
  <>
    <Route path={`${path}${SubPaths.create}`}>{children}</Route>
    <Route path={`${path}${SubPaths.edit}/:id`}>{children}</Route>
  </>
)

export const AuthenticatedRouter: React.FC = observer(() => {
  const {
    userStore: { getCurrentUser, currentUser },
  } = useStores()

  useEffect(() => {
    ;(async () => {
      await getCurrentUser()
    })()
  }, [])

  return (
    <Layout>
      <Switch>
        <Route exact path={RootPaths.index}>
          <Redirect to={RootPaths.categories} />
        </Route>
        <Route path={RootPaths.users} exact>
          {currentUser?.role === "SUPERADMIN" ? <UsersScreen /> : <Redirect to={RootPaths.categories} />}
        </Route>
        <CreateAndEditRoutes path={RootPaths.users}>
          {currentUser?.role === "SUPERADMIN" ? <UserScreen /> : <Redirect to={RootPaths.categories} />}
        </CreateAndEditRoutes>
        <Route path={RootPaths.userGroups} exact>
          {currentUser?.role === "SUPERADMIN" ? <UserGroupsScreen /> : <Redirect to={RootPaths.categories} />}
        </Route>
        <CreateAndEditRoutes path={RootPaths.userGroups}>
          {currentUser?.role === "SUPERADMIN" ? <UserGroupScreen /> : <Redirect to={RootPaths.categories} />}
        </CreateAndEditRoutes>
        <Route path={RootPaths.categories} exact>
          <CategoriesScreen />
        </Route>
        <CreateAndEditRoutes path={RootPaths.categories}>
          <CategoryScreen />
        </CreateAndEditRoutes>
        <Route path={RootPaths.subCategories} exact>
          <SubCategoriesScreen />
        </Route>
        <CreateAndEditRoutes path={RootPaths.subCategories}>
          <SubCategoryScreen />
        </CreateAndEditRoutes>
        <Route path={RootPaths.contents} exact>
          <ContentsScreen />
        </Route>
        <CreateAndEditRoutes path={RootPaths.contents}>
          <ContentScreen />
        </CreateAndEditRoutes>
        <Route path={RootPaths.dayPrograms} exact>
          <DayProgramsScreen />
        </Route>
        <CreateAndEditRoutes path={RootPaths.dayPrograms}>
          <DayProgramScreen />
        </CreateAndEditRoutes>
        <Route path={RootPaths.analytics} exact>
          <AnalyticsScreen />
        </Route>
        <Route path={RootPaths.appInfo} exact>
          <AppInfoScreen />
        </Route>
      </Switch>
    </Layout>
  )
})
