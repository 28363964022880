import React from "react"
import { makeStyles } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { color } from "../theme"
import { Button, Text } from "."
import { SubPaths } from "../routers/Paths"
import Add from "../static/Add.svg"

const useStyles = makeStyles({
  container: {
    display: "flex",
    flex: 1,
    flexFlow: "column nowrap",
    height: "100%",
    width: "100%",
  },
  headerContainer: {
    background: color.palette.white,
    width: "100%",
    display: "flex",
    flex: "0 0 auto",
    justifyContent: "space-between",
    alignItems: "center",
    height: 120,
    padding: "0 50px",
    borderBottom: `1px solid ${color.palette.transparentBlack}`,
  },
  header: {
    "@media (min-width:600px)": {
      fontSize: 44,
      fontWeight: 600,
    },
    fontSize: 30,
    fontWeight: 600,
    fontFamily: "Bw Surco",
  },
  content: {
    display: "flex",
    flex: 1,
    backgroundColor: color.palette.offWhite,
  },
  horizontal: {
    display: "flex",
    flex: 1,
  },
  createButton: {
    // width: 140,
    padding: "15px 20px",
    justifyContent: "center",
    alignItems: "center",
  },
  addIcon: {
    width: 15,
    height: 15,
    color: color.palette.white,
    marginRight: 10,
  },
})

interface ScreenProps {
  /**
   * Header title for the screen
   */
  headerTitle: string
  /**
   * Display a button that redirects to create screen for current content
   */
  createButton?: boolean
}

export const Screen: React.FC<ScreenProps> = ({ createButton, headerTitle, children }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const history = useHistory()

  const handleCreateClick = () => {
    history.push(`${history.location.pathname}${SubPaths.create}`)
  }

  const renderCreateButton = () => {
    if (createButton) {
      return (
        <Button className={classes.createButton} onClick={handleCreateClick} primary>
          <img src={Add} className={classes.addIcon} alt="add icon" />
          <Text preset="buttonCreate" tx="common.new" />
        </Button>
      )
    }
    return null
  }

  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <h2 className={classes.header}>{t(headerTitle)}</h2>
        {renderCreateButton()}
      </div>
      <div className={classes.content}>{children}</div>
    </div>
  )
}
