import React from "react"
import { observer } from "mobx-react"
import { Screen, DayProgramAnalytics, ContentAnalytics } from "../../components"
import { makeStyles } from "@material-ui/styles"

const useStyles = makeStyles({
  container: {
    padding: "20px 50px",
    display: "flex",
    flexDirection: "column",
    maxWidth: 1400,
    width: "100%",
    flex: 1,
    msFlexDirection: "column",
  },
  filterContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    margin: "26px 0",
  },
})

export const AnalyticsScreen: React.FC = observer(() => {
  const { container } = useStyles()

  return (
    <Screen headerTitle="screens.analytics">
      <div className={container}>
        <DayProgramAnalytics />
        <ContentAnalytics />
      </div>
    </Screen>
  )
})
