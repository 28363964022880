import { EditorState } from "draft-js"
import React, { FC, useState, useEffect, ChangeEvent } from "react"
import { useTranslation } from "react-i18next"
import { ContentSectionType, CONTENT_SECTIONS, IContentSection, IDayProgramSection } from "eteva-types"

import { editorStateToHtml } from "../../utils"
import { Audio, GifWithPreview, ContentSectionWithPreview, DayProgramSectionWithPreview, ImageWithPreview } from "../../utils/interfaces"
import {
  FormImageField,
  FormInputField,
  FormWysiwygField,
  FormSubmitField,
  FormSelectField,
  FormContentSectionField,
  FormFieldBase,
} from "./FormTypes"
import { useFormStyles, useImageStyles, useSectionStyles, useSelectStyles } from "./styles"
import { htmlToEditorState } from "../../utils/transforms"
import SelectInput, { SelectInputOption } from "../SelectInput"
import { Text } from "../Text"
import { FormContentSections, FormDayProgramSections } from "./FormRepeaterContent"
import Add from "../../static/add-icon.svg"
import { Input } from "../Input"
import { ImageUploadInput } from "../ImageUploadInput"
import { Editor } from "../Editor"
import Switch from "../Switch"
import { Button } from "../Button"
import { ContentEditor } from "./ContentEditor"

const Required: FC<{ field: FormFieldBase }> = ({ field }) => {
  if (!field.required) return null
  return <span>*</span>
}

interface FormInputProps {
  field: FormInputField
  value: number | string
  onChange: (fieldId: string, e: any) => void
}
export const FormInput: FC<FormInputProps> = ({ field, onChange, value }) => {
  const classes = useFormStyles()
  const { t } = useTranslation()

  const handleChange = (e: any) => {
    onChange(field.id, e.target.value)
  }
  return (
    <>
      <label htmlFor={field.id} className={classes.inputHeader}>
        {t(field.label)}
        <Required field={field} />
      </label>
      <Input
        value={value}
        id={field.id}
        className={field.style === "short" ? classes.shorterInput : ""}
        onChange={handleChange}
      />
    </>
  )
}

interface FormImageProps {
  field: FormImageField
  onChange: (fieldId: string, img: ImageWithPreview) => void
  imagePreviewUrl?: string
}
export const FormImage: FC<FormImageProps> = ({ field, onChange, imagePreviewUrl }) => {
  const imageClasses = useImageStyles()
  const classes = useFormStyles()
  const { t } = useTranslation()
  const [image, setImage] = useState<ImageWithPreview | undefined>()

  useEffect(() => {
    setImage({ imagePreviewUrl })
  }, [imagePreviewUrl])

  const handleImageChange = (file: Blob | null) => {
    if (file) {
      const reader = new FileReader()
      reader.onloadend = () => {
        const obj = { image: file, imagePreviewUrl: reader.result as string }
        onChange(field.id, obj)
        setImage(obj)
      }
      reader.readAsDataURL(file)
    }
  }

  const handleDelete = () => setImage(undefined)

  return (
    <>
      <label className={classes.inputHeader}>
        {t(field.label)}
        <Required field={field} />
      </label>
      <ImageUploadInput
        id={field.id}
        className={imageClasses.imageInput}
        onChange={handleImageChange}
        onDelete={handleDelete}
        previewUrl={image && (image.imagePreviewUrl as string)}
      />
    </>
  )
}

interface FormWysiwygProps {
  field: FormWysiwygField
  onChange: (fieldId: string, htmlContent: string) => void
  value: string
}
export const FormWysiwyg: FC<FormWysiwygProps> = ({ field, value, onChange }) => {
  const classes = useFormStyles()
  const { t } = useTranslation()
  const [state, setState] = useState(EditorState.createEmpty())

  useEffect(() => {
    setState(htmlToEditorState(value))
  }, [value])

  const handleEditorChange = (state: EditorState) => {
    setState(state)
  }

  const handleBlur = () => {
    const htmlVal = editorStateToHtml(state)
    onChange(field.id, htmlVal)
  }

  return (
    <>
      <label className={classes.inputHeader}>
        {t(field.label)}
        <Required field={field} />
      </label>
      <Editor state={state} onChange={handleEditorChange} onBlur={handleBlur} />
    </>
  )
}

export const FormToggleable: FC<{ label: string }> = ({ children, label }) => {
  const [showAdditional, setShowAdditional] = useState(false)
  const classes = useFormStyles()
  const { t } = useTranslation()

  const handleAdditionalToggle = () => {
    setShowAdditional(!showAdditional)
  }

  return (
    <>
      <div className={classes.switchContainer}>
        <Switch checked={showAdditional} onChange={handleAdditionalToggle} />
        <span className={classes.additionalInfoText}>{t(label)}</span>
      </div>
      <div style={{ display: showAdditional ? "" : "none" }}>{children}</div>
    </>
  )
}

interface FormSubmitProps {
  field: FormSubmitField
  isDisabled: boolean
  isEditing?: boolean
  onSubmit?: () => void
  onCancel?: () => void
}
export const FormSubmit: FC<FormSubmitProps> = ({ field, isEditing, onSubmit, onCancel, isDisabled }) => {
  const { t } = useTranslation()
  const classes = useFormStyles()
  const text = isEditing ? field.updateLabel : field.addLabel
  return (
    <div className={classes.buttonContainer}>
      <Button disabled={isDisabled} onClick={onSubmit} primary>
        <p className={classes.buttonContent}>{t(text)}</p>
      </Button>
      <Button className={classes.cancelButton} onClick={onCancel}>
        <p className={classes.buttonContent}>{t("common.cancel")}</p>
      </Button>
    </div>
  )
}

interface FormSelectProps {
  field: FormSelectField
  onChange: (fieldId: string, e: any) => void
  value: unknown
}
export const FormSelect: FC<FormSelectProps> = ({ field, onChange, value }) => {
  const { t } = useTranslation()
  const classes = useSelectStyles()

  const handleChange = (e: any) => {
    onChange(field.id, e.target.value)
  }

  return (
    <>
      <label className={classes.inputHeader}>
        {t(field.label)}
        <Required field={field} />
      </label>
      <SelectInput
        id={field.id}
        options={field.options}
        value={value}
        onChange={handleChange}
        multiple={field.multiple}
        loading={field.loading}
        placeholder={field.multiple ? "form.selectAtLeastOne" : "form.selectOne"}
      />
    </>
  )
}

interface FormContentSectionEditorProps {
  field: FormContentSectionField
  onSave: (fieldId: string, section: Partial<ContentSectionWithPreview>) => void
  contentSections?: IContentSection[]
  onRemove: (section: ContentSectionWithPreview) => void
  onEditClick: (section: Partial<ContentSectionWithPreview>) => void
  onMoveUpClick: (section: Partial<ContentSectionWithPreview>) => void
  onMoveDownClick: (section: Partial<ContentSectionWithPreview>) => void
}
export const FormContentSectionEditor: FC<FormContentSectionEditorProps> = ({
  field,
  onSave,
  contentSections = [],
  onRemove,
  onEditClick,
  onMoveUpClick,
  onMoveDownClick,
}) => {
  const { t } = useTranslation()
  const classes = useSectionStyles()
  const [visible, setVisible] = useState(false)
  const [selectedType, setSelectedType] = useState<ContentSectionType | "">("")
  const [content, setContent] = useState<EditorState | string | undefined>()
  const [linkText, setLinkText] = useState("")
  const [image, setImage] = useState<ImageWithPreview | undefined>()
  const [audio, setAudio] = useState<Audio | undefined>()
  const [gif, setGif] = useState<GifWithPreview | undefined>()
  const [editingSection, setEditingSection] = useState<Partial<ContentSectionWithPreview>>()

  const handleEditClick = (section: Partial<ContentSectionWithPreview>) => {
    if (onEditClick && section.type) {
      setSelectedType(section.type)
      if (section.type === "wysiwyg" && section.content) {
        setContent(htmlToEditorState(section.content))
      } else if (section.type === "image") {
        setImage({
          image: section.imageFile,
          imageId: section.imageId,
          imagePreviewUrl: (section.imagePreviewUrl as string) ?? null,
        })
      } else if (section.type === 'audio') {
        setAudio({
          audio: section.audioFile,
          audioId: section.audioId
        })
      } else if (section.type === 'gif') {
        setGif({
          gif: section.gifFile,
          gifId: section.gifId,
          gifPreviewUrl: (section.gifPreviewUrl as string) ?? null
        })
      }
       else {
        setContent(section.content)
        section.linkText && setLinkText(section.linkText)
      }
      setVisible(true)
      onEditClick(section)
      setEditingSection(section)
    }
  }

  const handleSelectType = (e: any) => {
    setContent(undefined)
    setLinkText("")
    setSelectedType(e.target.value)
  }

  const handleCancel = () => {
    setImage(undefined)
    setLinkText("")
    setEditingSection(undefined)
    setContent(undefined)
    setSelectedType("")
    setVisible(false)
  }

  const handleRemoveImage = () => {
    setImage(undefined)
    editingSection && setEditingSection({ ...editingSection, imageId: undefined })
  }

  const handleRemoveAudio = () => {
    setAudio(undefined)
    editingSection && setEditingSection({ ...editingSection, audioId: undefined })
  }

  const handleRemoveGif = () => {
    setGif(undefined)
    editingSection && setEditingSection({ ...editingSection, gifId: undefined })
  }

  const handleSave = () => {
    if (selectedType !== "") {
      let transformedContent
      if (selectedType === "wysiwyg") {
        transformedContent = editorStateToHtml(content as EditorState)
      } else {
        transformedContent = content
      }
      if (editingSection) {
        onSave(field.id, {
          ...editingSection,
          type: selectedType,
          content: transformedContent as any,
          imageFile: image?.image,
          audioFile: audio?.audio,
          gifFile: gif?.gif,
          gifPreviewUrl: gif?.gifPreviewUrl as string,
          imagePreviewUrl: image?.imagePreviewUrl as string,
          linkText,
        })
      } else {
        onSave(field.id, {
          type: selectedType,
          content: transformedContent as any,
          imageFile: image?.image,
          audioFile: audio?.audio,
          gifFile: gif?.gif,
          gifPreviewUrl: gif?.gifPreviewUrl as string,
          imagePreviewUrl: image?.imagePreviewUrl as string,
          linkText,
          position: contentSections.length + 1,
        })
      }
      handleCancel()
    }
  }

  const handleChangeContent = (e: EditorState | ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | null) => {
    if (e instanceof EditorState) return setContent(e)
    setContent(e?.target.value)
  }

  const handleChangeLinkText = (val: string) => {
    setLinkText(val)
  }

  const sectionTypeOptions: SelectInputOption[] = CONTENT_SECTIONS.map(it => ({ value: it, label: t(`content.${it}`) }))

  const getPreviewUrl = () => {
    if (editingSection) return editingSection.imageId || image?.imagePreviewUrl
    return image?.imagePreviewUrl
  }

  const renderButtons = () => {
    if (!selectedType.length) return null
    return (
      <div className={classes.buttonContainer}>
        <Button onClick={handleSave} primary tight className={classes.leftButton}>
          <Text preset="buttonCreate" tx={editingSection ? "content.update" : "content.save"} />
        </Button>
        <Button onClick={handleCancel} tight>
          <Text preset="buttonCreate" tx="common.cancel" />
        </Button>
      </div>
    )
  }

  const renderContent = () => {
    if (!visible)
      return (
        <Button onClick={() => setVisible(true)} primary tight>
          <img src={Add} style={{ height: 20, width: 20 }} alt="add icon" />
          <Text preset="buttonCreate" tx="content.new" />
        </Button>
      )
    return (
      <>
        <SelectInput
          options={sectionTypeOptions}
          value={selectedType}
          onChange={handleSelectType}
          placeholder="content.sectionTypePlaceholder"
          className={classes.selectInput}
        />
        <ContentEditor
          selectedType={selectedType}
          content={content}
          onRemoveAudio={handleRemoveAudio}
          onChangeAudio={setAudio}
          onChangeGif={setGif}
          onRemoveGif={handleRemoveGif}
          onChangeContent={handleChangeContent}
          onChangeImage={setImage}
          onRemoveImage={handleRemoveImage}
          linkText={linkText}
          onChangeLinkText={handleChangeLinkText}
          imagePreviewUrl={getPreviewUrl()}
        />
        {renderButtons()}
      </>
    )
  }

  return (
    <>
      <label className={classes.inputHeader}>
        {t(field.label)}
        <Required field={field} />
      </label>
      <div className={classes.container}>
        <FormContentSections
          contentSections={contentSections}
          onRemove={onRemove}
          onEditClick={handleEditClick}
          onMoveUpClick={onMoveUpClick}
          onMoveDownClick={onMoveDownClick}
        />
        {renderContent()}
      </div>
    </>
  )
}

interface FormDayProgramSectionEditorProps {
  field: FormContentSectionField
  onSave: (fieldId: string, section: Partial<DayProgramSectionWithPreview>) => void
  contentSections?: IDayProgramSection[]
  onRemove: (section: DayProgramSectionWithPreview) => void
  onEditClick: (section: Partial<DayProgramSectionWithPreview>) => void
  onMoveUpClick: (section: Partial<ContentSectionWithPreview>) => void
  onMoveDownClick: (section: Partial<ContentSectionWithPreview>) => void
}
export const FormDayProgramSectionEditor: FC<FormDayProgramSectionEditorProps> = ({
  field,
  onSave,
  contentSections = [],
  onRemove,
  onEditClick,
  onMoveUpClick,
  onMoveDownClick,
}) => {
  const { t } = useTranslation()
  const classes = useSectionStyles()
  const [visible, setVisible] = useState(false)
  const [selectedType, setSelectedType] = useState<ContentSectionType | "">("")
  const [content, setContent] = useState<EditorState | string | undefined>()
  const [image, setImage] = useState<ImageWithPreview | undefined>()

  const [linkText, setLinkText] = useState("")
  const [currentSection, setCurrentSection] = useState(1)
  const [editingSection, setEditingSection] = useState<Partial<DayProgramSectionWithPreview>>()

  useEffect(() => {
    if (contentSections) {
      // Get the highest section number that existing sections have
      const listSections = [...new Set(contentSections.map(it => it.section))]
      setCurrentSection(listSections.length ? Math.max(...listSections) : 1)
    }
  }, [contentSections])

  const handleEditClick = (section: Partial<DayProgramSectionWithPreview>) => {
    if (onEditClick && section.type) {
      setSelectedType(section.type)
      if (section.type === "wysiwyg" && section.content) {
        setContent(htmlToEditorState(section.content))
      } else {
        setContent(section.content)
        section.linkText && setLinkText(section.linkText)
      }
      setVisible(true)
      onEditClick(section)
      setEditingSection(section)
    }
  }

  const handleSelectType = (e: any) => {
    setContent(undefined)
    setLinkText("")
    setSelectedType(e.target.value)
  }

  const handleCancel = () => {
    setImage(undefined)
    setEditingSection(undefined)
    setContent(undefined)
    setSelectedType("")
    setLinkText("")
    setVisible(false)
  }

  const handleRemoveImage = () => {
    setImage(undefined)
    editingSection && setEditingSection({ ...editingSection, imageId: undefined })
  }
  const handleSave = () => {
    if (selectedType !== "") {
      let transformedContent
      if (selectedType === "wysiwyg") {
        transformedContent = editorStateToHtml(content as EditorState)
      } else {
        transformedContent = content
      }
      if (editingSection) {
        onSave(field.id, {
          ...editingSection,
          type: selectedType,
          content: transformedContent as any,
          imageFile: image?.image,
          imagePreviewUrl: image?.imagePreviewUrl as string,
          linkText,
        })
      } else {
        onSave(field.id, {
          type: selectedType,
          content: transformedContent as any,
          imageFile: image?.image,
          imagePreviewUrl: image?.imagePreviewUrl as string,
          linkText,
          position: contentSections.length + 1,
          section: currentSection,
        })
      }
      handleCancel()
    }
  }

  const handleChangeContent = (e: EditorState | ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | null) => {
    if (e instanceof EditorState) return setContent(e)
    setContent(e?.target.value)
  }

  const handleChangeLinkText = (val: string) => {
    setLinkText(val)
  }

  const isSaveSectionDisabled = () =>
    !contentSections || !contentSections.find(cs => cs.section === currentSection) || Boolean(editingSection)

  const getPreviewUrl = () => {
    if (editingSection) return editingSection.imageId || image?.imagePreviewUrl
    return image?.imagePreviewUrl
  }

  const renderContent = () => {
    if (!visible) return null

    const sectionTypeOptions: SelectInputOption[] = CONTENT_SECTIONS.map(it => ({
      value: it,
      label: t(`content.${it}`),
    }))

    return (
      <>
        <SelectInput
          options={sectionTypeOptions}
          value={selectedType}
          onChange={handleSelectType}
          placeholder="content.sectionTypePlaceholder"
          className={classes.selectInput}
        />
        <ContentEditor
          selectedType={selectedType}
          content={content}
          onChangeContent={handleChangeContent}
          onChangeImage={setImage}
          onChangeGif={() => {}}
          onRemoveGif={() => {}}
          onChangeAudio={() => {}}
          onRemoveAudio={() => {}}
          onRemoveImage={handleRemoveImage}
          linkText={linkText}
          onChangeLinkText={handleChangeLinkText}
          imagePreviewUrl={getPreviewUrl()}
        />
      </>
    )
  }

  const renderAddOrSaveButton = () => {
    if (visible)
      return (
        <Button onClick={handleSave} primary tight className={classes.leftButton} disabled={!content && !image}>
          <Text preset="buttonCreate" tx={editingSection ? "content.update" : "content.save"} />
        </Button>
      )
    return (
      <Button onClick={() => setVisible(true)} primary tight className={classes.leftButton}>
        <img src={Add} style={{ height: 20, width: 20 }} alt="add icon" />
        <Text preset="buttonCreate" tx="content.new" />
      </Button>
    )
  }

  const renderButtons = () => {
    return (
      <div className={classes.buttonContainer}>
        {renderAddOrSaveButton()}
        <Button onClick={() => setCurrentSection(currentSection + 1)} disabled={isSaveSectionDisabled()} primary tight>
          <Text preset="buttonCreate" text={t("form.saveCurrentSection", { section: currentSection })} />
        </Button>
      </div>
    )
  }

  return (
    <>
      <label className={classes.inputHeader}>
        {t(field.label)}
        <Required field={field} />
      </label>
      <div className={classes.container}>
        <FormDayProgramSections
          sections={contentSections}
          onRemove={onRemove}
          onEditClick={handleEditClick}
          onMoveUpClick={onMoveUpClick}
          onMoveDownClick={onMoveDownClick}
        />
        {renderContent()}
        {renderButtons()}
      </div>
    </>
  )
}
