import React from "react"
import { useTranslation } from "react-i18next"
import { TableHead, TableRow, TableCell, Checkbox } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { color } from "../../theme/"

const useStyles = makeStyles({
  root: {
    height: 50,
  },
  tableHead: {
    textAlign: "center",
    backgroundColor: color.palette.white,
    color: color.text,
    fontFamily: "Bw Surco",
    fontSize: 18,
    fontWeight: 600,
    minWidth: 60,
    padding: "0px 16px",
    border: `1px solid ${color.line}`,
    borderTopWidth: 0,
    "&:first-child": {
      borderLeftWidth: 0,
    },
    "&:last-child": {
      borderRightWidth: 0,
      padding: "0px 16px",
    },
  },
  tighterHead: {
    minWidth: 0,
    padding: "0 8px",
  },
})

interface TableHeaderProps {
  properties: {
    key: number | symbol | string
    title: string
  }[]
  edit?: boolean
  onSelectAll?: () => void
  selected?: boolean
  hideCheckbox?: boolean
}

const TableHeader: React.FC<TableHeaderProps> = ({ properties, edit, onSelectAll, selected, hideCheckbox }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const renderHeaderTitles = () => {
    if (!properties.length) return null
    return properties.map(it => (
      <TableCell key={String(it.key)} className={classes.tableHead}>
        {t(it.title)}
      </TableCell>
    ))
  }

  const renderEdit = () => {
    if (!edit) return null
    return <TableCell className={`${classes.tableHead} ${classes.tighterHead}`}>{t("common.edit")}</TableCell>
  }

  return (
    <TableHead>
      <TableRow classes={{ root: classes.root }}>
        {!hideCheckbox && (
          <TableCell className={`${classes.tableHead} ${classes.tighterHead}`}>
            <Checkbox checked={selected} onClick={onSelectAll} />
          </TableCell>
        )}
        <TableCell className={`${classes.tableHead} ${classes.tighterHead}`}>{"ID"}</TableCell>
        {renderHeaderTitles()}
        {renderEdit()}
      </TableRow>
    </TableHead>
  )
}

export default TableHeader
