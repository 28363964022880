import { makeStyles } from "@material-ui/core"
import { color } from "../../theme/color"
import { metrics } from "../../theme/metrics"

export const useStyles = makeStyles({
  container: {
    ...metrics.pageContentPadding,
  },
  buttonContent: {
    fontSize: 18,
    textTransform: "capitalize",
    padding: "0px 30px",
    margin: 0,
  },
})

export const useImageStyles = makeStyles({
  imageInputContainer: {
    display: "flex",
    flexFlow: "row wrap",
  },
  imageInput: {
    margin: "0 1rem 1rem 0",
  },
})

export const useFormStyles = makeStyles({
  formContainer: {
    maxWidth: 900,
    display: "flex",
    flexFlow: "column nowrap",
    backgroundColor: color.palette.white,
    border: `1px solid ${color.line}`,
    padding: "0px 40px 20px 40px",
    flex: "1 1 auto",
    marginBottom: 50,
    borderRadius: 3,
  },
  switchContainer: {
    display: "flex",
    margin: "22px 0",
  },
  additionalInfoText: {
    display: "inline-flex",
    alignItems: "center",
    margin: "5px",
  },
  container: {
    width: "auto",
  },
  shorterInput: {
    margin: "0 30px 0 0",
  },
  inputContainer: {
    margin: "0 0 30px 0",
  },
  inputHeader: {
    fontSize: 16,
    fontWeight: 600,
    fontFamily: "Bw Surco",
    margin: "1.5em 0 1em",
    display: "block",
  },
  buttonContainer: {
    marginTop: 24,
  },
  cancelButton: {
    marginLeft: 16,
  },
  buttonContent: {
    fontSize: 16,
    fontWeight: 600,
    margin: "5px 20px",
  },
  separator: {
    height: 1,
    width: "100%",
    margin: "30px 0",
    backgroundColor: color.line,
  },
})

export const useSelectStyles = makeStyles({
  inputHeader: {
    fontSize: 16,
    fontWeight: 600,
    fontFamily: "Bw Surco",
    margin: "1.5em 0 1em",
    display: "block",
  },
})

export const useSectionStyles = makeStyles({
  inputHeader: {
    fontSize: 16,
    fontWeight: 600,
    fontFamily: "Bw Surco",
    margin: "1.5em 0 1em",
    display: "block",
  },
  addIcon: {
    width: 20,
    height: 20,
  },
  selectInput: {
    width: "50%",
    marginBottom: 16,
  },
  container: {
    padding: "16px 0",
    display: "flex",
    flexDirection: "column",
  },
  buttonContainer: {
    padding: "16px 0",
  },
  leftButton: {
    marginRight: 16,
  },
  altTextLabel: {
    margin: "6px 0",
  },
  altTextInput: {
    width: "50%",
  },
})

export const useRepeaterStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    padding: 6,
    position: "relative",
  },
  wysiwyg: {
    fontSize: 20,
    color: color.text,
  },
  imgPreview: {
    maxWidth: "100%",
    height: "auto",
    alignSelf: "center",
  },
  youtubeWrapper: {
    position: "relative",
    paddingBottom: "56.25%",
  },
  iframe: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  },
  sectionButtonContainer: {
    position: "absolute",
    top: 20,
    right: 20,
    display: "flex",
    flexDirection: "row",
  },
  sectionButton: {
    border: "none",
    backgroundColor: color.palette.transparentBlack,
    width: 20,
    height: 20,
    borderRadius: 10,
    marginLeft: 5,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    outline: "none",
    transition: "all 250ms",
    zIndex: 99,
    "&:hover": {
      backgroundColor: color.dim,
    },
  },
  icon: {
    width: 18,
    height: 18,
    filter: "invert(100%)",
  },
  editIcon: {
    width: 13,
    height: 13,
    filter: "invert(100%)",
  },
  section: {
    padding: "6px 0",
  },
  sectionNumber: {
    fontSize: 22,
  },
})
