import React, { ReactElement } from "react"
import { useTranslation } from "react-i18next"
import { TableCell, TableRow as MUITableRow, IconButton, Checkbox } from "@material-ui/core"
import { Edit } from "@material-ui/icons"
import { makeStyles } from "@material-ui/styles"
import { color } from "../../theme"
import { CommonAttributes } from "eteva-types"
import { TableProperty } from "./Table"

const useStyles = makeStyles({
  root: {
    backgroundColor: color.palette.white,
    height: 50,
    transition: "all 150ms",
  },
  hover: {
    "&$hover:hover": {
      backgroundColor: color.backgroundDarker,
    },
  },
  cell: {
    fontSize: 16,
    fontFamily: "Roboto",
    color: color.text,
    textAlign: "center",
    border: `1px solid ${color.line}`,
    padding: "0 12px",
    borderBottomWidth: 0,
    "&:first-child": {
      borderLeftWidth: 0,
    },
    "&:last-child": {
      borderRightWidth: 0,
    },
  },
  editBtn: {
    padding: 0,
    width: 40,
    height: 40,
    borderRadius: 20,
  },
  deleteButton: {
    color: color.palette.angry,
  },
  tighterCell: {
    width: 50,
    padding: "0 4px",
  },
})

type TableRowProps<T> = {
  item: T
  properties: TableProperty<T>[]
  edit?: boolean
  onEditClick?: (itemId: number) => void
  onSelectOne?: (id: number) => void
  selected?: boolean
  hideCheckbox?: boolean
}

export function TableRow<T extends CommonAttributes>(props: TableRowProps<T>): ReactElement {
  const classes = useStyles()
  const { t } = useTranslation()
  const { item, onEditClick, properties, edit, selected, onSelectOne, hideCheckbox } = props

  const handleEditClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation()
    if (onEditClick) onEditClick(item.id as number)
  }

  const handleCheckboxClick = () => {
    if (onSelectOne && item.id) onSelectOne(item.id)
  }

  const renderCells = () => {
    if (!item) return null
    return properties.map(it => {
      const cellValue = () => {
        const val = item[it.key]
        if (typeof val === "boolean") return t(`common.${String(val)}`)
        if (it.key === "role") return t(`users.roles.${val}`)
        return String(val)
      }
      return (
        <TableCell key={it.key as string} className={classes.cell}>
          {cellValue()}
        </TableCell>
      )
    })
  }

  const renderEditCell = () => {
    if (!edit) return null
    return (
      <TableCell className={`${classes.cell} ${classes.tighterCell}`}>
        <IconButton onClick={handleEditClick} className={classes.editBtn} aria-label={t("common.edit")}>
          <Edit />
        </IconButton>
      </TableCell>
    )
  }

  return (
    <MUITableRow classes={{ root: classes.root, hover: classes.hover }} hover>
      {!hideCheckbox && (
        <TableCell className={`${classes.cell} ${classes.tighterCell}`}>
          <Checkbox checked={selected} onClick={handleCheckboxClick} />
        </TableCell>
      )}
      <TableCell className={`${classes.cell} ${classes.tighterCell}`}>{item.id}</TableCell>
      {renderCells()}
      {renderEditCell()}
    </MUITableRow>
  )
}

export default TableRow
