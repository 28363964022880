import React, { FC, useEffect, KeyboardEvent } from "react"
import { useTranslation } from "react-i18next"
import InputBase from "@material-ui/core/InputBase"
import SearchIcon from "@material-ui/icons/Search"
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import { color } from "../../theme"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    search: {
      height: 40,
      minWidth: 200,
      position: "relative",
      flex: 1,
      maxWidth: 400,
      borderRadius: theme.shape.borderRadius,
      transition: "all 250ms",
      "&:hover": {
        backgroundColor: color.palette.white,
      },
      marginRight: 20,
      marginLeft: 0,
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(3),
        width: "auto",
      },
      border: `1px solid ${color.line}`,
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    inputRoot: {
      color: "inherit",
      width: "100%",
    },
    inputInput: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      fontFamily: "Bw Surco",
      padding: theme.spacing(1, 1, 1, 0),
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create("width"),
      width: "100%",
    },
  })
)

export interface SearchBarProps {
  width?: number | string
  placeholder?: string
  onChange?: (e: KeyboardEvent<HTMLInputElement>) => void
}

export const SearchBar: FC<SearchBarProps> = ({ width, placeholder, onChange }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const styles = width ? { width } : {}

  let timeout: NodeJS.Timeout

  useEffect(() => {
    return () => {
      clearTimeout(timeout)
    }
  }, [])

  const handleSearch = (e: KeyboardEvent<HTMLInputElement>) => {
    if (onChange) {
      timeout = setTimeout(() => {
        onChange(e)
      }, 300)
    }
  }

  return (
    <div className={classes.search} style={styles}>
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
      <InputBase
        onKeyUp={handleSearch}
        placeholder={t(placeholder || "")}
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        inputProps={{ "aria-label": "search" }}
      />
    </div>
  )
}
