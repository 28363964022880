import * as React from "react"
import { api } from "../../services/api"

type ImageProps = React.ImgHTMLAttributes<HTMLImageElement> & {
  imageId?: number
  width?: number
  height?: number
  fit?: "cover" | "contain" | "fill" | "inside" | "outside"
  source?: string
}

export const Image: React.FC<ImageProps> = props => {
  const { className, imageId, width, height, fit = "cover", source, ...rest } = props
  const roundedWidth = width ? Math.round(width) : NaN
  const roundedHeight = height ? Math.round(height) : NaN
  const uri = imageId && api.getImageUri({ id: imageId, width: roundedWidth, height: roundedHeight, fit })

  return <img className={className} src={uri || source} {...rest} />
}
